import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Briefcase, ArrowLeft, Share2, CheckCircle, Zap, Gift } from 'lucide-react';
import { JobsData } from './components/JobsData';
import { TimeAgo } from './components/TimeAgo';

const JobDetailPage = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = React.useState(false);

  // Use job from state if available; otherwise, look it up from fallback data.
  let job = state?.job;
  if (!job) {
    job = JobsData.find((j) => j.slug === slug);
  }

  // Determine the meta description.
  // First, use the custom description if available.
  // Otherwise, fallback to the first paragraph from the first section.
  const metaDescription =
    job?.description ||
    (job?.sections && job.sections[0]?.paragraphs && job.sections[0].paragraphs[0]) ||
    'Job listing at Asendia AI';

  // Handle the "Share" button click to copy the job link
  const handleShare = () => {
    const jobLink = window.location.href; // Get the current URL
    navigator.clipboard.writeText(jobLink) // Copy the URL to clipboard
      .then(() => {
        setIsCopied(true); // Show "Copied!" feedback
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(() => {
        alert('Failed to copy link. Please try again.');
      });
  };

  // If no job is found, show an error message and a back button
  if (!job) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center p-8 max-w-md">
          <p className="text-gray-600 mb-4">No job data found.</p>
          <button
            onClick={() => navigate('/')}
            className="bg-gray-800 text-white px-6 py-2 rounded-lg hover:bg-gray-900 transition-colors"
          >
            Return to Listings
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Set dynamic meta tags */}
      <Helmet>
        <title>{job.title} | Asendia AI Jobs</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <button
            onClick={() => navigate(-1)}
            className="group flex items-center gap-2 text-gray-800 hover:text-gray-900 transition-all"
          >
            <div className="p-2 rounded-lg bg-gray-100 group-hover:bg-gray-200 transition-colors">
              <ArrowLeft className="w-5 h-5" />
            </div>
            <span className="font-medium">Back to Listings</span>
          </button>
          <button
            onClick={handleShare}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-indigo-50 hover:bg-gray-200 transition-all shadow-sm"
          >
            <Share2 className="w-5 h-5 text-gray-600" />
            <span className="font-medium text-gray-600">
              {isCopied ? 'Copied to Clipboard!' : 'Share Position'}
            </span>
          </button>
        </div>

        {/* Job Title Section */}
        <div className="relative mb-8">
          <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-200">
            <h1 className="text-4xl font-bold text-gray-900 mb-3">
              {job.title}
            </h1>
            <div className="flex flex-wrap gap-3 mb-4">
              <span className="px-3 py-1 bg-indigo-50/70 text-gray-800 rounded-full text-sm font-medium">
                {job.hours}
              </span>
              <span className="px-3 py-1 bg-indigo-50/70 text-gray-800 rounded-full text-sm font-medium">
                {job.type}
              </span>
              <span className="px-3 py-1 bg-indigo-50/70 text-gray-800 rounded-full text-sm font-medium">
                🌍 {job.location}
              </span>
            </div>
          </div>
        </div>

        {/* Company Profile */}
        <div className="opacity-90 flex items-center gap-4 mb-12 p-6 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-2xl border border-indigo-100">
          <div className="flex-shrink-0">
            <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-indigo-700 to-purple-600 flex items-center justify-center text-white font-bold text-xl shadow-lg">
              A
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <span className="text-sm font-medium text-gray-600">Posted by</span>
              <span className="text-md font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent animate-gradient-x">
                ASENDIA AI
              </span>
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-1">
              <Zap className="w-4 h-4 text-purple-500" />
              <span>Next-gen AI-powered interview platform</span>
            </div>
          </div>
        </div>

        {/* Main Content Grid */}
        <div className="grid lg:grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-12">
            {job.sections?.map((section, index) => (
              <section
                key={index}
                className="group relative pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-6 before:bg-indigo-300 before:rounded-full"
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {section.title}
                </h2>
                <div className="space-y-6">
                  {section.paragraphs?.map((para, idx) => (
                    <p
                      key={idx}
                      className="text-gray-700 leading-relaxed bg-white p-4 rounded-xl shadow-sm border border-gray-200"
                    >
                      {para}
                    </p>
                  ))}
                  {section.bullets && (
                    <ul className="space-y-4">
                      {section.bullets.map((bullet, bIdx) => (
                        <li
                          key={bIdx}
                          className="flex items-start gap-3 text-gray-700"
                        >
                          <CheckCircle className="w-5 h-5 text-gray-600 flex-shrink-0 mt-1" />
                          <span>{bullet}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </section>
            ))}
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-1">
            <div className="sticky top-16 space-y-8">
              <div className="bg-white rounded-2xl p-8 border border-gray-200 shadow-lg">
                {/* Salary Card */}
                <div className="mb-8">
                  <div className="text-2xl font-bold text-gray-900 mb-1">
                    {job.salary}
                  </div>
                  <div className="text-sm text-gray-500">Total compensation</div>
                  <div className="mt-4 h-px bg-gray-200" />
                </div>

                {/* Job Details */}
                <div className="space-y-6">
                  <div className="flex items-start gap-4">
                    <Briefcase className="w-6 h-6 text-gray-600 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-900">{job.type}</h3>
                      <p className="text-sm text-gray-500">
                        {job.hours} · {job.location}
                      </p>
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <a href="http://jobseeker.asendia.ai/apply" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center gap-2 bg-indigo-700 text-white py-3 px-6 rounded-xl font-semibold hover:bg-indigo-600 transition-all shadow-lg">
                    <Zap className="w-5 h-5" />
                    Apply Now
                  </a>

                  <a href="http://jobseeker.asendia.ai/referrals" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center gap-2 py-3 px-6 rounded-xl font-semibold border-2 border-gray-200 text-gray-800 hover:border-gray-300 hover:bg-gray-100 transition-all">
                    <Gift className="w-5 h-5" />
                    Refer & Earn
                  </a>
                </div>

                {/* Posted Date */}
                <div className="mt-8 pt-6 border-t border-gray-200">
                  <p className="text-sm text-gray-500">{TimeAgo(job.postedDate)}</p>
                </div>
              </div>

              {/* Additional Info Card */}
              <div className="bg-gray-50 rounded-2xl p-6 border border-gray-200">
                <h3 className="font-semibold text-gray-800 mb-3">
                  ✨ Perks & Benefits
                </h3>
                <ul className="space-y-3 text-sm text-gray-800">
                  <li className="flex items-center gap-2">
                    <CheckCircle className="w-4 h-4 text-gray-600" />
                    Remote-first culture
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle className="w-4 h-4 text-gray-600" />
                    Generous equity options
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle className="w-4 h-4 text-gray-600" />
                    AI learning budget
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailPage;
