// src/components/Blog.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import badisPicture from "../../../assets/badis_picture.jpg";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 50, damping: 20 },
  },
};

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all blog posts from the API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://jobseeker.asendia.ai/api/blogs');
        if (!response.ok) {
          throw new Error(`Error fetching blogs: ${response.statusText}`);
        }
        const data = await response.json();
        setBlogPosts(data.blogs || []);
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Blogs ─ Asendia AI</title>
        <meta
          name="description"
          content="Discover insightful blogs on how AI agents are revolutionizing recruitment, helping businesses save time, cut costs, and find the best talent faster."
        />
        <meta property="og:title" content="Our Blogs ─ Asendia AI" />
        <meta
          property="og:description"
          content="Explore how AI agents are transforming the recruitment landscape, offering smarter and more efficient hiring solutions."
        />
        <meta property="og:image" content="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png" />
        <meta property="og:url" content="https://asendia.ai.com/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Blogs ─ Asendia AI" />
        <meta name="twitter:description" content="Discover insightful blogs on how AI agents are revolutionizing recruitment, helping businesses save time, cut costs, and find the best talent faster." />
        <meta name="twitter:image" content="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff48f6697-17b0-42d8-815c-eeba2dcfc249_1128x686.png" />
      </Helmet>
      
      <motion.div
        className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Title Section */}
        <div className="w-full flex mb-16">
          <section className="max-w-[45rem] mx-auto flex flex-col items-center text-center space-y-6">
            <motion.h2
              className="-mb-1 bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent text-lg font-normal"
              variants={itemVariants}
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              Our Blogs
            </motion.h2>
            <motion.h1
              className="text-3xl sm:text-4xl font-bold text-slate-900"
              variants={itemVariants}
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              Discover insightful{" "}
              <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                Blogs
              </span>{" "}
              to help you achieve more
            </motion.h1>
          </section>
        </div>
        {/* End Title Section */}

        {loading ? (
          <div className="text-center">Loading blogs...</div>
        ) : error ? (
          <div className="text-center text-red-500">Error: {error}</div>
        ) : (
          <motion.div
            className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6"
            variants={containerVariants}
          >
            {blogPosts.map((post) => (
              <motion.div
                key={post.id}
                variants={itemVariants}
                whileHover={{ scale: 1, boxShadow: '0px 10px 20px rgba(106, 106, 106, 0.14)' }}
                whileTap={{ scale: 0.95 }}
                initial="hidden"
                className="rounded-3xl"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                <Link
                  to={`/blogs/${post.slug ? post.slug : post.id}`}
                  className="group flex shadow-lg flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg focus:outline-none focus:border-transparent focus:shadow-lg transition duration-300 rounded-3xl p-5"
                >
                  <div className="aspect-w-16 aspect-h-11 min-h-[230px] flex justify-center items-center">
                    <img
                      className="w-full object-cover rounded-xl"
                      src={post.main_image || post.imgSrc}
                      alt={post.title}
                    />
                  </div>
                  <div className="my-6">
                    <h3 className="text-xl font-semibold text-gray-800">
                      {post.title}
                    </h3>
                    <p className="mt-5 text-gray-600">
                      {post.description}
                    </p>
                  </div>
                  <div className="mt-auto flex items-center gap-x-3">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={post.author.imgSrc || badisPicture}
                      alt={post.author.name || post.author}
                    />
                    <div>
                      <h5 className="text-sm text-gray-800">
                        By {post.author.name || post.author}
                      </h5>
                      <p className="text-xs text-gray-500">{post.author.date || post.date}</p>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Blog;
