// src/App.js

import React, { Suspense, lazy, useEffect, useState, Component } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import CookieConsentBanner from "./Components/CookieConsentBanner/CookieConsentBanner";
import bgShadows from "../src/assets/bgShadows.png";

import ForJobSeekers from "./Pages/ForJobSeekers/ForJobSeekers";
import CookieSettings from "./Pages/CookieSettings/CookieSettings.js";
import Blog from "./Pages/BlogSection/BlogsSection/BlogSection.jsx";
import BlogDetail from "./Pages/BlogSection/BlogsDetail/BlogsDetail.jsx";
import FeaturePage from "./Pages/AsendiaFeatureSection/FeaturePage.jsx";
import OurPartners from "./Pages/OurPartners/OurPartners.jsx";
import AsendiaAiPartnerForm from "./Pages/OurPartners/PartnersForm/PartnersForm.jsx";
import TalkToFounder from "./Pages/TalkToFounder/TalkToFounder.jsx";
import OurPartnersB2C from "./Pages/ForJobSeekers/OurPartners/OurPartners.jsx";
import EarlyAccess from "./Pages/ForJobSeekers/EarlyAccess/EarlyAccess.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition.jsx";
import HomePrice from "./Pages/ForJobSeekers/PricingPlans/PricingPlans.jsx";
import PricingSection from "./Pages/ForRecruiterPage/Sections/PricingPlan/PricingPlan.jsx";
import SubscribeModal from "./Components/NewsLetterModal/NewsLetterModal.jsx";
import JobSeekersPartnerForm from "./Pages/ForJobSeekers/OurPartners/components/JobSeekersPartnerForm.jsx";
import Templates from "./Pages/ForJobSeekers/TemplatesSection/Templates.jsx";
import InterviewTemplates from "./Pages/InterviewTemplates/InterviewTemplates.jsx";
import JobsPage from "./Pages/CareerJobs/CareerJobs.jsx";
import JobDetailPage from "./Pages/CareerJobs/JobDetailPage.jsx";
import WorkersTerms from "./Pages/WorkersTerms/WorkersTerms.jsx";

// Lazy loading components
const ForRecruiterPage = lazy(() => import("./Pages/ForRecruiterPage/ForRecruiterPage"));
const AboutUs = lazy(() => import("./Pages/Aboutus/Aboutus"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage/ErrorPage"));

// ErrorBoundary Component to catch runtime errors and lazy-load issues
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <h1>Something went wrong.</h1>
          <p>Please refresh the page or try again later.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

// ScrollToTop Component for smooth navigation on route change
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const htmlElement = document.documentElement;
    htmlElement.style.scrollBehavior = "auto";
    window.scrollTo({ top: 0 });
    requestAnimationFrame(() => {
      htmlElement.style.scrollBehavior = "";
    });
  }, [location.pathname]);

  return null;
}

function App() {
  const [cookiePreferences, setCookiePreferences] = useState(() => {
    try {
      const storedPreferences = document.cookie
        .split("; ")
        .find((row) => row.startsWith("cookiePreferences="));
      return storedPreferences
        ? JSON.parse(decodeURIComponent(storedPreferences.split("=")[1]))
        : null;
    } catch (error) {
      console.error("Error parsing cookie preferences:", error);
      return null;
    }
  });

  const handleCookieConsent = (preferences) => {
    setCookiePreferences(preferences);
    document.cookie = `cookiePreferences=${encodeURIComponent(
      JSON.stringify(preferences)
    )}; path=/; max-age=${60 * 60 * 24 * 365}`;

    if (preferences.analytics) {
      console.log("Analytics enabled");
    } else {
      console.log("Analytics disabled");
    }

    if (preferences.marketing) {
      console.log("Marketing enabled");
    } else {
      console.log("Marketing disabled");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hasSubscribed = localStorage.getItem("hasSubscribed");
    const hasDismissed = localStorage.getItem("hasDismissedSubscribeModal");

    if (!hasSubscribed && !hasDismissed) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  const handleSubscribe = async (email) => {
    try {
      const response = await fetch(`https://recruiter.asendia.ai/api/subscribe-newsletter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          route: location.pathname,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Subscription failed");
      }

      localStorage.setItem("hasSubscribed", true);
      localStorage.setItem("subscriberEmail", email);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    localStorage.setItem("hasDismissedSubscribeModal", true);
  };

  
  return (
    <div className="min-h-screen flex flex-col">
      {/* Scroll to top on route change */}
      <ScrollToTop />

      {/* Navbar */}
      <header className="sticky top-0 z-50">
        <Navbar />
      </header>

      {/* Main Content wrapped with ErrorBoundary and Suspense */}
      <main className="flex-grow">
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className="flex items-center justify-center h-full">
                <div className="text-center py-20">
                  <svg
                    className="animate-spin h-8 w-8 text-gray-600 mx-auto mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                  Loading...
                </div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ForJobSeekers />} />
              <Route path="/companies" element={<ForRecruiterPage />} />
              <Route path="/job-seekers" element={<ForJobSeekers />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/blogs/:slug" element={<BlogDetail />} />
              <Route path="/pricing" element={<PricingSection />} />
              <Route path="/job-seekers/pricing" element={<HomePrice />} />
              <Route path="/features" element={<FeaturePage />} />
              <Route path="/partners" element={<OurPartners />} />
              <Route path="/job-seekers/partners" element={<OurPartnersB2C />} />
              <Route path="/jobs" element={<JobsPage />} />
              <Route path="/jobs/:slug" element={<JobDetailPage />} />
              <Route path="/talk-to-founders" element={<TalkToFounder />} />
              <Route path="/early-access" element={<EarlyAccess />} />
              <Route path="/partners/partner-contact" element={<AsendiaAiPartnerForm />} />
              <Route path="/job-seekers/partners/partner-contact" element={<JobSeekersPartnerForm />} />
              <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
              <Route path="/job-seekers/templates" element={<InterviewTemplates />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/workers-terms" element={<WorkersTerms />} />
              <Route path="/cookie-policy" element={<CookieSettings setPreferences={handleCookieConsent} />} />
              {/* Catch-all Route */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </main>

      {/* Footer */}
      <Footer />

      {/* Cookie Consent Banner */}
      <CookieConsentBanner
        show={!cookiePreferences}
        onAccept={() =>
          handleCookieConsent({ essential: true, analytics: true, marketing: true })
        }
        onDecline={() =>
          handleCookieConsent({ essential: true, analytics: false, marketing: false })
        }
      />

      <SubscribeModal isOpen={isModalOpen} onClose={handleCloseModal} onSubscribe={handleSubscribe} />
    </div>
  );
}

export default App;
