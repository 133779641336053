// src/components/FeaturesSection.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import performance from "../../../assets/performance.png";
import laptop_dashboard_mockup from "../../../assets/laptop_dashboard_mockup.png";
import agents_mockup from "../../../assets/agents_mockup.png";
import asendia_logo_icon from "../../../assets/asendia_logo_icon.png";

// Custom hook to detect screen size
const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isDesktop;
};

const FeaturesSection = ({fromDesktop = false}) => {
    const isDesktop = useIsDesktop();

    // Container variants for staggered children animations
    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.2,
            },
        },
    };

    // Item variants for smooth entrance
    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: "easeOut" },
        },
    };

    return (
        <motion.section
            className="py-10 px-4 lg:px-8 lg:py-20 bg-gray-50"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={containerVariants}
        >
            {/* Desktop Version */}
            {isDesktop && (
                <div>
                    <div className="container mx-auto mb-10 text-center lg:mb-20">
                        <motion.h2
                            className="mb-2 text-md font-semibold uppercase bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-800 to-indigo-400 text-transparent"
                            variants={itemVariants}
                        >
                            Features
                        </motion.h2>
                        <motion.h3
                            className="mb-4 text-2xl font-bold lg:text-4xl text-gray-800"
                            variants={itemVariants}
                        >
                            Hire Smarter, Scale faster with AI-driven screening
                        </motion.h3>
                        <motion.p
                            className="mx-auto max-w-lg text-gray-500"
                            variants={itemVariants}
                        >
                           Stop wasting time on unqualified candidates. Sarah automatically screens, evaluates, and ranks top talent while you focus on scaling your team. Move from application to offer 10x faster – without lifting a finger.
                        </motion.p>
                    </div>
                    <div className={fromDesktop ? 'px-[40px]' : 'px-[120px]'}>
                        <motion.div
                            className="container mx-auto grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-x-8"
                            variants={containerVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                        >
                            {/* Card 1 */}
                            <motion.div
                                className="h-full flex flex-col gap-4"
                                variants={itemVariants}
                            >
                                <motion.div
                                    className="flex-1 bg-white border shadow-sm p-8 rounded-3xl transition-transform transform hover:scale-105"
                                    variants={itemVariants}
                                >
                                    <h4 className="mb-4 text-lg font-medium text-gray-800">
                                        Tailored to Your Needs and 10x faster
                                    </h4>
                                    <p className="mb-6 text-gray-500">
                                        Accelerate hiring with AI-driven automation tailored to your
                                        workflows.
                                    </p>
                                    <motion.img
                                        src={performance}
                                        alt="Performance"
                                        className="object-contain w-full h-40"
                                        initial={{ opacity: 0, scale: 0.95 }}
                                        whileInView={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 0.5, ease: "easeOut" }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    />
                                </motion.div>
                                <motion.div
                                    className="flex-1 bg-white border shadow-sm p-6 rounded-3xl transition-transform transform hover:scale-105"
                                    variants={itemVariants}
                                >
                                    <h4 className="mb-4 text-lg font-medium text-gray-800">
                                        Global Talent Access in Seconds
                                    </h4>
                                    <p className="mb-6 text-gray-500">
                                        Instantly connect with a world-wide Pool of A-star candidates.
                                    </p>
                                    <div className="flex items-center justify-center space-x-4 mt-6">
                                        <motion.img
                                            className="object-cover w-12 h-12 rounded-full border-2 border-white"
                                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&w=64&q=80"
                                            alt="user 1"
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.1 }}
                                            viewport={{ once: true, amount: 0.3 }}
                                        />
                                        <motion.img
                                            className="object-cover w-12 h-12 rounded-full border-2 border-white"
                                            src="https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&w=64&q=80"
                                            alt="user 2"
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.2 }}
                                            viewport={{ once: true, amount: 0.3 }}
                                        />
                                        <motion.img
                                            className="object-cover w-12 h-12 rounded-full border-2 border-white"
                                            src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=64&q=80"
                                            alt="user 3"
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.3 }}
                                            viewport={{ once: true, amount: 0.3 }}
                                        />
                                        <motion.img
                                            className="object-cover w-12 h-12 rounded-full border-2 border-white"
                                            src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=64&q=80"
                                            alt="user 4"
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.4 }}
                                            viewport={{ once: true, amount: 0.3 }}
                                        />
                                    </div>
                                </motion.div>
                            </motion.div>

                            {/* Card 2 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-3xl lg:col-span-2 overflow-hidden transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-4 text-lg font-medium text-gray-800">
                                    Detailed Candidate Insights
                                </h4>
                                <p className="mb-6 text-gray-500">
                                    Receive in-depth candidate assessment reports and interview
                                    recordings.
                                </p>
                                <motion.div
                                    className="flex justify-center"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6, ease: "easeOut" }}
                                    viewport={{ once: true, amount: 0.3 }}
                                >
                                    <img
                                        src={laptop_dashboard_mockup}
                                        alt="Laptop Dashboard"
                                        className="shadow-lg border p-2 bg-gradient-to-b from-indigo-100 via-indigo-50 to-white rounded-3xl lg:h-[480px] md:h-[300px] h-[220px] lg:translate-y-16 translate-y-10 object-contain -mt-8"
                                    />
                                </motion.div>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            className="container mx-auto mt-8 grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-x-8"
                            variants={containerVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                        >
                            {/* Card 3 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-3xl lg:col-span-2 overflow-hidden transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-4 text-lg font-medium text-gray-800">
                                    One-Click AI Interview Automation
                                </h4>
                                <p className="mb-6 text-gray-500">
                                    Seamlessly create and deliver thousands of customized AI-driven
                                    interviews with a single click, optimizing efficiency and
                                    precision in your hiring process.
                                </p>
                                <motion.img
                                    src={agents_mockup}
                                    alt="AI Assistants"
                                    className="w-full xl:h-[355px] lg:h-[380px] md:h-[300px] h-[180px] lg:translate-y-6 translate-y-10 object-cover object-top"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6, ease: "easeOut" }}
                                    viewport={{ once: true, amount: 0.3 }}
                                />
                            </motion.div>

                            {/* Card 4 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-3xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-4 text-lg font-medium text-gray-800">
                                    AI-Powered Interview Insights
                                </h4>
                                <p className="mb-6 text-gray-500">
                                    Experience a seamless conversation between a candidate and an AI
                                    interviewer.
                                </p>
                                <div className="space-y-4">
                                    {/* Bot's message (Interviewer) */}
                                    <motion.div
                                        className="flex items-start space-x-4"
                                        variants={itemVariants}
                                    >
                                        <img
                                            className="w-10 h-10 rounded-full border border-gray-200 p-2 object-contain"
                                            src={asendia_logo_icon}
                                            alt="bot"
                                        />
                                        <div className="bg-gray-100 p-4 rounded-lg shadow">
                                            <p className="text-[13px] font-semibold text-indigo-700">
                                                Asendia AI
                                            </p>
                                            <p className="text-[13px] text-gray-800">
                                                Can you describe your expertise in cloud technologies?
                                            </p>
                                        </div>
                                    </motion.div>

                                    {/* Person's response (Interviewee) */}
                                    <motion.div
                                        className="flex items-start justify-end space-x-4"
                                        variants={itemVariants}
                                    >
                                        <div className="bg-indigo-100 p-4 rounded-lg shadow">
                                            <p className="text-[13px] text-gray-900">
                                                I have extensive experience with AWS and Azure, focusing on
                                                scalable deployments and serverless architectures.
                                            </p>
                                        </div>
                                        <img
                                            className="w-10 h-10 rounded-full border-2 border-gray-300 object-cover"
                                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&w=64&q=80"
                                            alt="user"
                                        />
                                    </motion.div>

                                    <motion.div
                                        className="flex items-start space-x-4"
                                        variants={itemVariants}
                                    >
                                        <img
                                            className="w-10 h-10 rounded-full border border-gray-200 p-2 object-contain"
                                            src={asendia_logo_icon}
                                            alt="bot"
                                        />
                                        <div className="bg-gray-100 p-4 rounded-lg shadow">
                                            <p className="text-[13px] font-semibold text-indigo-700">
                                                Asendia AI
                                            </p>
                                            <p className="text-[13px] text-gray-800">
                                                How do you ensure scalability in your applications?
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            )}

            {/* Mobile Version */}
            {!isDesktop && (
                <div>
                    <div className="container mx-auto mb-6 text-center">
                        <motion.h2
                            className="mb-2 text-sm font-semibold uppercase bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-800 to-indigo-400 text-transparent"
                            variants={itemVariants}
                        >
                            Features
                        </motion.h2>
                        <motion.h3
                            className="mb-4 text-xl font-bold text-gray-800"
                            variants={itemVariants}
                        >
                            Our Cutting-Edge Generative AI Solution
                        </motion.h3>
                        <motion.p
                            className="mx-auto max-w-md text-gray-500"
                            variants={itemVariants}
                        >
                            Asendia AI revolutionizes HR with Generative AI, automating tasks,
                            delivering insights, and enabling data-driven decisions.
                        </motion.p>
                    </div>
                    <div className="px-4">
                        <motion.div
                            className="container mx-auto flex flex-col gap-6"
                            variants={containerVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                        >
                            {/* Card 1 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-2xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-2 text-md font-medium text-gray-800">
                                    Tailored to Your Needs and 10x faster
                                </h4>
                                <p className="mb-4 text-gray-500">
                                    Accelerate hiring with AI-driven automation tailored to your
                                    workflows.
                                </p>
                                <motion.img
                                    src={performance}
                                    alt="Performance"
                                    className="object-contain w-full h-32 mb-4"
                                    initial={{ opacity: 0, scale: 0.95 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    viewport={{ once: true, amount: 0.3 }}
                                />
                            </motion.div>

                            {/* Card 2 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-2xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-2 text-md font-medium text-gray-800">
                                    Global Talent Access in Seconds
                                </h4>
                                <p className="mb-4 text-gray-500">
                                    Instantly connect with a world-wide Pool of A-star candidates.
                                </p>
                                <div className="flex items-center justify-center space-x-3 mt-4">
                                    <motion.img
                                        className="object-cover w-10 h-10 rounded-full border-2 border-white"
                                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&w=64&q=80"
                                        alt="user 1"
                                        initial={{ opacity: 0, y: 10 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    />
                                    <motion.img
                                        className="object-cover w-10 h-10 rounded-full border-2 border-white"
                                        src="https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&w=64&q=80"
                                        alt="user 2"
                                        initial={{ opacity: 0, y: 10 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.2 }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    />
                                    <motion.img
                                        className="object-cover w-10 h-10 rounded-full border-2 border-white"
                                        src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=64&q=80"
                                        alt="user 3"
                                        initial={{ opacity: 0, y: 10 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.3 }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    />
                                    <motion.img
                                        className="object-cover w-10 h-10 rounded-full border-2 border-white"
                                        src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=64&q=80"
                                        alt="user 4"
                                        initial={{ opacity: 0, y: 10 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.4 }}
                                        viewport={{ once: true, amount: 0.3 }}
                                    />
                                </div>
                            </motion.div>

                            {/* Card 3 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-2xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-2 text-md font-medium text-gray-800">
                                    Detailed Candidate Insights
                                </h4>
                                <p className="mb-4 text-gray-500">
                                    Receive in-depth candidate assessment reports and interview
                                    recordings.
                                </p>
                                <motion.img
                                    src={laptop_dashboard_mockup}
                                    alt="Laptop Dashboard"
                                    className="shadow-lg border p-2 bg-gradient-to-b from-indigo-100 via-indigo-50 to-white rounded-2xl w-full h-48 object-contain mt-2"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6, ease: "easeOut" }}
                                    viewport={{ once: true, amount: 0.3 }}
                                />
                            </motion.div>

                            {/* Card 4 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-2xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-2 text-md font-medium text-gray-800">
                                    One-Click AI Interview Automation
                                </h4>
                                <p className="mb-4 text-gray-500">
                                    Seamlessly create and deliver thousands of customized AI-driven
                                    interviews with a single click, optimizing efficiency and
                                    precision in your hiring process.
                                </p>
                                <motion.img
                                    src={agents_mockup}
                                    alt="AI Assistants"
                                    className="w-full h-32 object-cover object-top mt-2"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6, ease: "easeOut" }}
                                    viewport={{ once: true, amount: 0.3 }}
                                />
                            </motion.div>

                            {/* Card 5 */}
                            <motion.div
                                className="bg-white border shadow-sm p-6 rounded-2xl transition-transform transform hover:scale-105"
                                variants={itemVariants}
                            >
                                <h4 className="mb-2 text-md font-medium text-gray-800">
                                    AI-Powered Interview Insights
                                </h4>
                                <p className="mb-4 text-gray-500">
                                    Experience a seamless conversation between a candidate and an AI
                                    interviewer.
                                </p>
                                <div className="space-y-3">
                                    {/* Bot's message (Interviewer) */}
                                    <motion.div
                                        className="flex items-start space-x-3"
                                        variants={itemVariants}
                                    >
                                        <img
                                            className="w-8 h-8 rounded-full border border-gray-200 p-1 object-contain"
                                            src={asendia_logo_icon}
                                            alt="bot"
                                        />
                                        <div className="bg-gray-100 p-3 rounded-lg shadow">
                                            <p className="text-[12px] font-semibold text-indigo-700">
                                                Asendia AI
                                            </p>
                                            <p className="text-[12px] text-gray-800">
                                                Can you describe your expertise in cloud technologies?
                                            </p>
                                        </div>
                                    </motion.div>

                                    {/* Person's response (Interviewee) */}
                                    <motion.div
                                        className="flex items-start justify-end space-x-3"
                                        variants={itemVariants}
                                    >
                                        <div className="bg-indigo-100 p-3 rounded-lg shadow">
                                            <p className="text-[12px] text-gray-900">
                                                I have extensive experience with AWS and Azure, focusing on
                                                scalable deployments and serverless architectures.
                                            </p>
                                        </div>
                                        <img
                                            className="w-8 h-8 rounded-full border-2 border-gray-300 object-cover"
                                            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&w=64&q=80"
                                            alt="user"
                                        />
                                    </motion.div>

                                    <motion.div
                                        className="flex items-start space-x-3"
                                        variants={itemVariants}
                                    >
                                        <img
                                            className="w-8 h-8 rounded-full border border-gray-200 p-1 object-contain"
                                            src={asendia_logo_icon}
                                            alt="bot"
                                        />
                                        <div className="bg-gray-100 p-3 rounded-lg shadow">
                                            <p className="text-[12px] font-semibold text-indigo-700">
                                                Asendia AI
                                            </p>
                                            <p className="text-[12px] text-gray-800">
                                                How do you ensure scalability in your applications?
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            )}
        </motion.section>
    );
};

export default FeaturesSection;
