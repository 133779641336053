
const PrivacyPolicy = ({ closeModal }) => {


    return (
        <div className="w-full max-w-[1000px] mx-auto p-4">
            {/* Header div */}
            <div>
                <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] text-gray-900">
                    Privacy Policy
                </h1>
                <p className="text-center text-gray-800 dark:text-gray-800 text-[16px] mb-12">
                    Asendia inc (“Asendia inc”, “us”, “our”, and “we”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.
                </p>
            </div>

            {/* Privacy Policy Content */}
            <div>
                {/* Introduction */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        By accepting our Privacy Policy and Terms of Use (located at <a href="http://asendia.ai/terms-and-conditions" className="text-blue-500">ttp://asendia.ai/terms-and-conditions</a>), you consent to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy. Capitalized terms in this policy have the same meaning given to them in our Terms of Use, unless otherwise defined in this policy.
                    </p>
                </div>

                {/* Children's Online Privacy Protection Act */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Children’s Online Privacy Protection Act
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        Minors under eighteen (18) years of age are prohibited from using the Platform and Services. The Platform and Services are not intended for children under the age of 18. If you are under 18 years old, please do not provide personally identifiable information of any kind whatsoever on the Platform or Services. We do not intentionally gather Personal Information from visitors who are under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us.
                    </p>
                </div>

                {/* Types of Data We Collect */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Types of Data We Collect
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. “Personal Information” means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data.
                    </p>
                </div>

                {/* Information You Provide to Us */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4">
                        Information You Provide to Us
                    </h3>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2">
                        <li>
                            We may collect Personal Information from you, such as your first and last name, e-mail and mailing address, phone number, gender, and password when you complete a contact form on our Platform, or create or log-in to your Account.
                        </li>
                        <li>
                            When you purchase the Services, our payment processor will collect all information necessary to complete the transaction, including your name, credit card information, billing information.
                        </li>
                        <li>
                            When you use the AI interview feature, audio recordings of your mock interviews are captured. Video recordings may also be taken depending on the features you choose to use. These audio and video recordings, along with other information you provide (such as your CV, LinkedIn profile, and any feedback on your performance), are stored and may be used to generate assessments or provide personalized insights.
                        </li>
                        <li>
                            We also retain information on your behalf, such as files, messages, and any additional notes or comments that you store using your Account.
                        </li>
                        <li>
                            If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.
                        </li>
                        <li>
                            When you participate in one of our surveys, we may collect additional information that you knowingly provide.
                        </li>
                        <li>
                            When you post messages on the Services or on a Social Media Platform where we have a page or presence the information contained in your posting may be republished on our Platform and other users may be able to see them.
                        </li>
                    </ul>
                </div>

                {/* Information Collected via Technology */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4">
                        Information Collected via Technology
                    </h3>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2">
                        <li>
                            In an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. We may also ask advertisers or other partners to serve ads to your devices, which may use cookies or similar technology.
                        </li>
                        <li>
                            We may also use third-party analytics services such as Google Analytics or Google Adsense to collect information about how you use and interact with our Services. Such third-party analytics services may use cookies to gather information such as the pages you visited, your IP address, a date/time stamp for your visit and which site referred you to the Platform. This helps us provide a better user experience and improve usability.
                        </li>
                        <li>
                            We may utilize pixels provided by various third-party web analytics and marketing services (such as Facebook, Google, and others) to help us understand and improve our advertising efforts. These pixels enable us to track conversions from ads, optimize ad performance based on collected data, build targeted audiences for future ads, and remarket to people who have previously interacted with our Platform. When you visit our Platform, these pixels may collect information about your interaction with our Platform, such as the pages you view, products or services you explore, and whether you complete any actions, like making a purchase. This data is collected through the use of cookies and similar technologies. The collected information may be shared with the respective third-party service providers and used in accordance with their data use policies. These third-party providers use the data to offer insights into the effectiveness of our ads and to deliver targeted advertising based on your online activity. We do not share any of your personally identifiable information directly with these third parties through the pixels. You have the right to opt out of the collection and use of your information for targeted advertising. For information on managing and deleting cookies, please refer to your browser’s help documentation. Additionally, many advertising services allow you to control ad preferences and opt-out options within their account settings.
                        </li>
                    </ul>
                </div>

                {/* Use of Your Personal Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Use of Your Personal Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        Depending on the features of the Platform you use, we may process your personal data based on one or more of the following legal bases:
                    </p>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2 mt-4">
                        <li>
                            <strong>Your Consent:</strong> By using the Platform, you give us permission to process your data to enhance your experience on the Platform. Example: Collecting and processing audio and visual recordings of your mock interviews in order to provide assessments and recommendations.
                        </li>
                        <li>
                            <strong>Contractual Obligations:</strong> We may process personal data to fulfill our contractual obligations to you, such as managing your Asendia inc account and delivering the requested services.
                        </li>
                        <li>
                            <strong>Legitimate Interests:</strong> We may process personal data to improve our services, manage the Platform more effectively, and secure our services, as long as these interests do not override your rights. Examples: Conducting vulnerability assessments to protect your data or analyzing usage data to identify and fix bugs.
                        </li>
                        <li>
                            <strong>Legal Obligations:</strong> We may be required to process certain personal data to comply with applicable laws and regulations.
                        </li>
                    </ul>
                    <p className="text-gray-800 text-[16px] leading-relaxed mt-4">
                        Below is a table that describes some of the purposes for which we process your personal data, the legal basis for processing, and examples where applicable:
                    </p>

                    {/* Table */}
                    <div className="overflow-x-auto mt-4">
                        <table className="min-w-full text-gray-800 text-[16px] leading-relaxed">
                            <thead>
                                <tr>
                                    <th className="border px-4 py-2">Purpose of Processing</th>
                                    <th className="border px-4 py-2">Legal Basis for Processing</th>
                                    <th className="border px-4 py-2">Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border px-4 py-2">Account Management and Security</td>
                                    <td className="border px-4 py-2">Contractual necessity</td>
                                    <td className="border px-4 py-2">To create and secure your account on our Platform, verify your identity, and manage user access.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Service Provision and Personalization</td>
                                    <td className="border px-4 py-2">Contractual necessity and consent</td>
                                    <td className="border px-4 py-2">To provide and personalize the services offered through our Platform. This includes integrating and displaying relevant data from your profile (such as your CV and performance assessments) to enhance your experience during mock interviews and feedback sessions. Additionally, we may use this data to generate personalized improvement reports and match you with the most suitable interview scenarios or feedback to help you prepare for your job application process.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Personalized Content and Feature Delivery</td>
                                    <td className="border px-4 py-2">Legitimate interest</td>
                                    <td className="border px-4 py-2">To tailor content, features, and services based on your interactions and preferences on the Platform.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Administrative Communication</td>
                                    <td className="border px-4 py-2">Contractual necessity</td>
                                    <td className="border px-4 py-2">Send you notifications about your account, such as verification emails, security alerts, and maintenance updates.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Marketing and Promotional Activities</td>
                                    <td className="border px-4 py-2">Consent</td>
                                    <td className="border px-4 py-2">Send newsletters, offers, and promotions related to our Services or on behalf of third-party partners.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Customer Support and User Feedback</td>
                                    <td className="border px-4 py-2">Legitimate interest</td>
                                    <td className="border px-4 py-2">Respond to your inquiries, provide customer support, and address any questions or feedback you may have.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Data Analysis and Platform Improvements</td>
                                    <td className="border px-4 py-2">Legitimate interest</td>
                                    <td className="border px-4 py-2">Conduct data analysis to understand user engagement, improve the Platform, and ensure a quality user experience.</td>
                                </tr>
                                <tr>
                                    <td className="border px-4 py-2">Payment Processing</td>
                                    <td className="border px-4 py-2">Contractual necessity</td>
                                    <td className="border px-4 py-2">Process your payment information via third-party payment providers (e.g., Stripe) to enable purchases of services such as Buddy coins.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Worker Privacy Policy and Data Usage */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4">
                        Worker Privacy Policy and Data Usage
                    </h3>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2">
                        <li>
                            At Asendia Inc, we are committed to protecting the privacy and security of our users' personal information. This section outlines how we collect, use, and share data specific to users ("Workers") who use our platform to connect with potential employers and perform services such as job interviews and talent assessments.
                        </li>
                        <li>
                            By using the Asendia Inc platform and agreeing to our Terms of Service, you acknowledge and consent to the data practices described below.
                        </li>
                        <li>
                            <strong>Types of Data Collected for Workers:</strong>
                            <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                                <li>
                                    <strong>Interview recordings and transcriptions:</strong> Audio and/or video data captured during interviews.
                                </li>
                                <li>
                                    <strong>Resumes and profiles:</strong> Documents such as resumes, CVs, LinkedIn profiles, and other related information.
                                </li>
                                <li>
                                    <strong>Images:</strong> Visual data captured during video interviews, used to enhance Worker profiles.
                                </li>
                                <li>
                                    <strong>Performance assessments:</strong> Feedback, scoring, and recommendations generated from mock or real interviews.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Data Usage and Sharing:</strong>
                            <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                                <li>
                                    <strong>Connecting with employers:</strong> Worker data, including resumes and interview responses, is shared with potential employers hiring through Asendia Inc. Public information (e.g., LinkedIn profiles) may also be retrieved to supplement Worker profiles.
                                </li>
                                <li>
                                    <strong>Evaluation and feedback:</strong> Interview data may be sent to third-party tools and services, including but not limited to foundational models, artificial intelligence tools, and APIs, to facilitate assessments, generate performance reports, and provide personalized feedback.
                                </li>
                                <li>
                                    <strong>Profile enhancement:</strong> Images captured during video interviews may be processed through trusted third-party APIs to generate professional visuals displayed on Worker profiles, viewable by potential employers.
                                </li>
                                <li>
                                    <strong>Model improvement:</strong> Aggregated and anonymized Worker data may be used to improve Asendia Inc’s AI models and services. Personally identifiable information will not be included without explicit consent.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Data Retention and Deletion Requests:</strong> Workers may request the deletion of their data by contacting Asendia Inc support. We will handle such requests in compliance with applicable laws and regulations. However, some data may be retained to meet legal, regulatory, or business obligations, such as maintaining transaction records or audit logs.
                        </li>
                    </ul>
                </div>


                {/* Use of Non-Personal Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Use of Non-Personal Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        In general, we use Non-Personal Information to help us improve the Service and customize the user experience. This may include aggregated data on device type, browser type, general location, and usage patterns. We aggregate Non-Personal Information in order to track trends, analyze usage patterns on the Platform, improve our content and offerings, and tailor advertisements. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information. We reserve the right to use and disclose such Non-Personal Information to our partners, advertisers, and other third parties at our discretion, as this information does not identify individual users. If our information practices change at any time in the future, we will post the policy changes on the Platform so that you may opt out of any new information practices. We suggest that you check the Platform periodically if you are concerned about how your information is used.
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        We may use anonymized and aggregated data collected from users to train and improve our AI models, ensuring that we continuously enhance the services and user experience on our platform. This data will not include personally identifiable information and will not be shared with third parties for model training purposes without your explicit consent. If we decide to use any Personal Information for model training, we will update this Privacy Policy accordingly and seek your consent before proceeding with such use. You will always have control over whether your data is used in this way.
                    </p>
                </div>

                {/* Important Information About Platform Permissions */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Important Information About Platform Permissions
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        Most mobile platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without your consent. And these platforms have different permission systems for obtaining your consent. The iOS platform will alert you the first time our App wants permission to access certain types of data and will let you consent (or not consent) to that request. Android devices will notify you of the permissions that our App seeks before you first use the app, and your use of the app constitutes your consent.
                    </p>
                </div>

                {/* Disclosure of Your Personal Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Disclosure of Your Personal Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        Our company does not sell your personal information. However, to provide you with the Services or for other business purposes, we may share your personal information with third parties as described below:
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Third-Party Service Providers.</strong> We may share your Personal Information with third-party service providers to facilitate the Services we offer you through our Platform. Specifically, these service providers assist us in:
                    </p>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2 mt-2">
                        <li>
                            <strong>Payment Processing:</strong> Asendia inc uses third-party payment processors, such as Stripe, to facilitate in-app purchases. When you provide payment information to Asendia inc, we collect and process the necessary details for completing the transaction, including payment and billing information. Your payment details may be stored by our payment processors for future use, enabling faster processing of subsequent transactions. Asendia inc does not store sensitive financial information directly. Instead, this information is handled by Stripe in accordance with their privacy policy. We ensure that all data processing complies with applicable data protection regulations, including GDPR.
                        </li>
                        <li>
                            <strong>Account Creation, Verification, and Authentication:</strong> We enable account creation and authentication through Google Firebase. For email verification and one-time passwords (OTP), we use Google Firebase to securely verify your account.
                        </li>
                        <li>
                            <strong>Cloud Infrastructure:</strong> We store user data on Microsoft Azure, a third-party cloud provider, which helps us deliver, maintain, and secure our Platform’s infrastructure. Microsoft Azure follows strict security measures to protect the data stored in its cloud services, in compliance with data protection regulations, including GDPR.
                        </li>
                        <li>
                            <strong>Use of AI Tools:</strong> We may use artificial intelligence (AI) tools to analyze your responses and create personalized recommendations. These tools assess the data provided through your interactions with our platform to generate insights and suggestions intended to enhance your experience. Data may be processed and stored in locations both within and outside the European Economic Area (EEA), depending on the providers we use. For data processed outside the EEA, we ensure compliance with applicable data protection laws, such as the General Data Protection Regulation (GDPR), by implementing appropriate safeguards.
                        </li>
                        <li>
                            <strong>Hosting and Customer Support:</strong> Service providers help host our Platform, manage our servers, and provide customer support to address your inquiries.
                        </li>
                        <li>
                            <strong>Quality Assurance and Performance Monitoring:</strong> We conduct quality assurance testing and monitor the Platform’s performance to ensure it operates as expected.
                        </li>
                        <li>
                            <strong>Marketing and Data Analysis:</strong> Third parties may assist in marketing efforts, conducting data analysis to understand user engagement, and improving the Platform.
                        </li>
                        <li>
                            <strong>Technical Support and Troubleshooting:</strong> Our service providers offer technical support and assist in troubleshooting issues on the Platform.
                        </li>
                        <li>
                            <strong>Additional Services:</strong> We work with third parties to provide other services aimed at enhancing the user experience on our Platform.
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        These service providers are contractually obligated to protect the confidentiality and security of your Personal Information and to use it only as directed by us.
                    </p>

                    {/* Affiliates and Business Partners */}
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Affiliates and Business Partners.</strong> We may share your Personal Information with our affiliates and business partners to perform functions and provide services on our behalf, such as marketing initiatives or improving the Platform’s functionality. These partners are required to use your Personal Information only for the purposes outlined by us and in compliance with applicable laws.
                    </p>

                    {/* Corporate Transactions */}
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Corporate Transactions.</strong> In the event of a merger, acquisition, reorganization, sale of assets, or bankruptcy, your Personal Information may be transferred to a successor entity as part of the transaction. We will take reasonable steps to ensure that your Personal Information remains secure and is handled in accordance with this Privacy Policy.
                    </p>

                    {/* Other Disclosures */}
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Other Disclosures.</strong> Asendia inc is committed to protecting your privacy and the confidentiality of your personal health information. We will not disclose your Personal Information to any third parties except in the following limited circumstances, and only to the extent required or permitted by law:
                    </p>
                    <ul className="list-disc list-inside text-gray-800 text-[16px] leading-relaxed space-y-2 mt-2">
                        <li>
                            <strong>Legal Obligations:</strong> We may disclose Personal Information if required to comply with applicable laws, regulations, or legal processes (such as subpoenas, court orders, or other legal requests). In such cases, we will take reasonable measures to limit the scope of the information disclosed and, where possible, will notify you in advance.
                        </li>
                        <li>
                            <strong>Protection and Safety:</strong> We may disclose Personal Information to protect the rights, property, or safety of Asendia inc, our users, or others. This includes actions to detect, prevent, or otherwise address fraud, security, or technical issues, and to enforce our Terms of Use.
                        </li>
                        <li>
                            <strong>Responding to Government Requests:</strong> In the rare event we receive a request from a public authority, including for law enforcement or national security purposes, we will carefully evaluate the legality and necessity of the request. Where permitted, Asendia inc will strive to notify you and to seek the minimum disclosure possible in response to such requests. Your personal health information is treated with the utmost sensitivity, and we will take all possible steps to protect it from unwarranted disclosure.
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Commitment to Transparency.</strong> Asendia inc believes in transparency regarding our handling of personal health information. Should we receive requests from public authorities or other third parties for user data, we will evaluate each request carefully. We are committed to protecting your privacy and will take appropriate measures to ensure that any disclosures are in line with our privacy principles and, where possible, will notify you of such disclosures.
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Data Security During Disclosures.</strong> When disclosing your Personal Information to third parties as described above, we take steps to protect the confidentiality and security of your data. We strive to use industry-standard safeguards and require third parties to implement similar protections, ensuring your information remains secure throughout any processing.
                    </p>
                </div>

                {/* Links to Third Party Websites */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Links to Third Party Websites
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Platform and the Service. Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Platform or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.
                    </p>
                </div>

                {/* Your Rights Regarding the Use of Your Personal Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Your Rights Regarding the Use of Your Personal Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        You have the right at any time to prevent us from contacting you for marketing purposes. If you receive promotional communications from us, you can opt out of further communications by following the unsubscribe instructions provided in each email or by updating your marketing preferences in the “Settings” section of your account. Please note, however, that regardless of your promotional preferences, we may still send you essential administrative emails, including updates to our Privacy Policy or other non-marketing communications related to your use of the Service.
                    </p>
                </div>

                {/* Access, Correction, Deletion */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4">
                        Access, Correction, Deletion
                    </h3>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        In accordance with GDPR, you have the right to access, correct, and request the deletion of the personal data we hold about you. If you wish to access or amend any of your personal information, or if you would like to request that we delete information we have obtained about you through our Service, you may contact us at <a href="mailto:hello@Asendia inc.ai" className="text-blue-500">hello@Asendia inc.ai</a> with the subject line "Personal Information Query." Upon your request, we will delete or block any references to you in our database, where applicable and permissible under GDPR.
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        You can also update, correct, or delete some of your account information and preferences at any time by accessing your account settings page on the Service. While any changes you make will be reflected in our active user databases immediately or within a reasonable period, we may retain certain information for purposes such as backup, archiving, fraud prevention, legal compliance, or where we have a legitimate reason to retain such data, as permitted under applicable law.
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        Please note that if you decline to provide certain personal information, we may not be able to offer some of the features or functionality of the Service. You also have the right to object to the processing of your personal information on legitimate grounds, except where such processing is required or permitted by applicable law.
                    </p>
                </div>

                {/* Security of Your Personal Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Security of Your Personal Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your Personal Information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. While we use reasonable efforts to protect your Personal Information, we cannot guarantee its absolute security. By using our Service, you acknowledge that you understand and agree to assume these risks.
                    </p>
                </div>

                {/* How Long We Retain Your Data */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        How Long We Retain Your Data
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        We will keep hold of your data for as long as necessary to fulfill the purposes for which it was collected. The length of time we retain it will depend on any legal obligations we have (such as tax recording purposes), the nature of any contracts we have in place with you, the existence of your consent or our legitimate interests as a business. After this period, data will be deleted or anonymized unless required for legal reasons (such as tax obligations or contractual purposes).
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        Prior to the deletion of your data, we will send you a reminder email to inform you of the upcoming data deletion and provide you with an opportunity to download or retain any information you may need. If you wish to extend the retention period or if you have any concerns about the deletion of your data, you may contact us at <a href="mailto:hello@Asendia inc.ai" className="text-blue-500">hello@Asendia inc.ai</a> before the deletion date.
                    </p>
                </div>

                {/* Do Not Track Signals */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Do Not Track Signals
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        Certain state laws require us to indicate whether we honor “Do Not Track” settings in your browser. Asendia inc adheres to the standards set out in this Privacy Policy and does not monitor or follow any Do Not Track browser requests.
                    </p>
                </div>

                {/* Notices */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Notices
                    </h2>
                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4">
                        Notices to International Users
                    </h3>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        The Platform is hosted in the European Union (“EU”). If you are a User accessing the Platform or Services (or providing information that is hosted on or routed through the Platform) from the United States, or any other region with laws or regulations governing personal data collection, use, and disclosure, that differ from EU laws, please note that you are transferring your personal data to the EU which does not have the same data protection laws as the United States and other regions, and by providing your personal data you permit the use of your personal data for the uses identified above in accordance with the Privacy Policy.
                    </p>

                    <h3 className="text-[18px] font-semibold text-[#280f46] mb-4 mt-6">
                        Notice to California Residents
                    </h3>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        The California Consumer Privacy Act (“CCPA”) provides California consumers with specific rights regarding their Personal Information. You have the right to request that businesses subject to the CCPA: (1) disclose certain information to you about their collection and use of your Personal Information over the past 12 months; (2) delete Personal Information collected from you, subject to certain exceptions; and (3) ask whether the business sells Personal Information and right to opt-out of that sale. To request the above information, you may contact us at: <a href="mailto:hello@Asendia inc.ai" className="text-blue-500">hello@Asendia inc.ai</a> with the email subject as "California Disclosure Information."
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        California law requires that we provide you with a summary of your privacy rights under the California Online Privacy Protection Act (“California Act”) and the California Business and Professions Code. As required by the California Act, this Privacy Policy identifies the categories of personally identifiable information that we collect through our Platform about individual consumers who use or visit our Platform and the categories of third-party persons or entities with whom such personally identifiable information may be shared.
                    </p>
                </div>

                {/* Changes to This Privacy Policy */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Changes to This Privacy Policy
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        We reserve the right to change this policy and our Terms at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Platform and this privacy page for updates.
                    </p>
                </div>

                {/* Contact Information */}
                <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
                    <h2 className="text-[20px] font-semibold text-[#280f46] mb-4">
                        Contact Information
                    </h2>
                    <p className="text-gray-800 text-[16px] leading-relaxed">
                        If you have any questions regarding this Privacy Policy or the practices of the Platform please contact our Data Protection Officer by sending an email to <a href="mailto:hello@Asendia inc.ai" className="text-blue-500">hello@Asendia inc.ai</a>
                    </p>
                    <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
                        <strong>Last Updated:</strong> This Privacy Policy was last updated on January 20, 2025.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default PrivacyPolicy;