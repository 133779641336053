// src/components/BlogDetail.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import badisPicture from "../../../assets/badis_picture.jpg";
import NewsletterSection from '../../../Components/NewsLetter/NewsLetter';
import './BlogContent.css'; // Custom CSS for blog content and badges

const BlogDetail = () => {
  // Use "slug" from the URL for SEO-friendly routes.
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  console.log("slug",slug)
  // Fetch blog details using the slug
  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await fetch(`https://jobseeker.asendia.ai/api/blogs/slug/${slug}`);
        if (!response.ok) {
          throw new Error('Error fetching blog detail');
        }
        const data = await response.json();
        setPost(data.blog);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetail();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-gray-700">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-red-600">Error: {error}</p>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-xl text-gray-700">Blog post not found.</p>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} ─ Asendia AI</title>
        <meta name="description" content={post.description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${post.title} - Asendia AI`} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.main_image} />
        <meta property="og:url" content={`https://asendia.ai/blog/${post.slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${post.title} - Asendia AI`} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:image" content={post.main_image} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": "${post.title}",
              "description": "${post.description}",
              "image": "${post.main_image}",
              "author": {
                "@type": "Person",
                "name": "${post.author.name}",
                "image": "${post.author.imgSrc}"
              },
              "datePublished": "${new Date(post.author.date).toISOString()}",
              "publisher": {
                "@type": "Organization",
                "name": "Asendia AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://asendia.ai.com/image/asendia_logo_icon.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white antialiased">
        <div className="container mx-auto px-4">
          <article className="max-w-4xl mx-auto">
            {/* Header */}
            <header className="mb-8 text-center">
              <p className="blog-meta">
                <span className="time-badge">
                  <time dateTime={post.author.date}>{post.author.date}</time>
                </span>
             
              </p>
              <h1 className="mb-4 text-3xl font-normal leading-tight text-center text-gray-900 lg:mb-6 lg:text-5xl ">{post.title}</h1>
              <p className="blog-context">{post.description}</p>
            </header>

            {/* Render HTML content */}
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.content }} />

            {/* Author Footer */}
            <footer className="mt-12 border-t pt-6">
              <div className="flex items-center">
                <img
                  className="w-14 h-14 rounded-full mr-4"
                  src={post.author.imgSrc || badisPicture}
                  alt={post.author.name}
                />
                <div>
                  <p className="text-lg font-medium">{post.author.name}</p>
                  <p className="text-sm text-gray-500">{post.author.role}</p>
                </div>
              </div>
            </footer>
          </article>
        </div>
      </main>

      {/* Related Articles */}
      {post.relatedArticles && post.relatedArticles.length > 0 && (
        <aside aria-label="Related articles" className="py-8 lg:py-24 bg-gray-50">
          <div className="px-4 mx-auto max-w-screen-xl">
            <h2 className="mb-8 text-2xl font-bold text-gray-900">Related articles</h2>
            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
              {post.relatedArticles.map((related) => (
                <article key={related.id} className="max-w-xs">
                  <Link to={`/blogs/${related.slug ? related.slug : related.id}`}>
                    <img
                      src={related.imgSrc}
                      className="mb-5 rounded-3xl w-full h-48 object-cover"
                      alt={related.title}
                    />
                  </Link>
                  <h3 className="mb-2 text-xl font-bold text-gray-900">
                    <Link to={`/blogs/${related.slug ? related.slug : related.id}`} className="hover:underline">
                      {related.title}
                    </Link>
                  </h3>
                  <p className="mb-4 text-gray-500">
                    {related.description || 'Discover tips and strategies for enterprise design to enhance your business solutions.'}
                  </p>
                  <Link
                    to={`/blogs/${related.slug ? related.slug : related.id}`}
                    className="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 hover:no-underline"
                  >
                    {related.readTime}
                  </Link>
                </article>
              ))}
            </div>
          </div>
        </aside>
      )}

      {/* Newsletter Section */}
      <div className="container mx-auto px-4 mt-10">
        <NewsletterSection />
      </div>
    </>
  );
};

export default BlogDetail;
