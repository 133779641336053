import React from 'react';

const WorkersTerms = ({ closeModal }) => {
  return (
    <div className="w-full max-w-[1000px] mx-auto p-4">
      {/* Page Header */}
      <div>
        <h1 className="text-[26px] font-bold text-center mb-4 sm:text-[36px] text-gray-900">
          Asendia Inc Worker Terms of Service
        </h1>
        <p className="text-center text-gray-800 text-[16px] mb-12">
          Please read the following terms carefully before using our platform.
        </p>
      </div>

      {/* Agreement Overview */}
      <div>
        <h2 className="text-[22px] font-semibold text-[#280f46] mb-6">
          Agreement Overview
        </h2>
        <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
          <p className="text-gray-800 text-[16px] leading-relaxed">
            This Agreement is made between the individual performing services ("Worker") and Asendia Inc ("Asendia"). A Worker refers to any user who uses our platform to connect with potential employers and perform services. A Worker may perform services for one of Asendia Inc's clients, partners, or contacts ("Client"). By using the Asendia platform, the Worker agrees to comply with the terms outlined in this Agreement.
          </p>
        </div>
      </div>

      {/* Non-Circumvention */}
      <div>
        <h2 className="text-[22px] font-semibold text-[#280f46] mb-6">
          Non-Circumvention
        </h2>
        <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
          <p className="text-gray-800 text-[16px] leading-relaxed">
            The Worker acknowledges that Asendia has a proprietary interest in its relationships with clients, partners, and contacts. The Worker understands that they may be introduced to these parties directly or indirectly through the course of their engagement with Asendia.
          </p>
          <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
            The Worker agrees not to engage, directly or indirectly, in any paid or unpaid capacity, with any individual, company, or organization introduced to them by Asendia during their engagement and for a period of two (2) years following the termination of their engagement, without obtaining prior written consent from Asendia. Any breach of this provision will be considered a material breach of this agreement and may result in legal action.
          </p>
        </div>
      </div>

      {/* Salary Negotiations */}
      <div>
        <h2 className="text-[22px] font-semibold text-[#280f46] mb-6">
          Salary Negotiations
        </h2>
        <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
          <p className="text-gray-800 text-[16px] leading-relaxed">
            The Worker agrees to conduct all salary discussions and negotiations exclusively through the Asendia platform. Direct negotiations with Asendia’s clients, partners, or contacts outside of the platform are strictly prohibited.
          </p>
          <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
            Compensation terms offered through Asendia are final and binding; any modifications must be processed and documented within the platform to ensure transparency and consistency. Violations of this provision may result in the termination of the Worker’s engagement with Asendia.
          </p>
        </div>
      </div>

      {/* Modification of Terms */}
      <div>
        <h2 className="text-[22px] font-semibold text-[#280f46] mb-6">
          Modification of Terms
        </h2>
        <div className="mb-8 p-6 bg-[#f7f7f747] bg-opacity-90 border backdrop-saturate-150 rounded-xl shadow-md">
          <p className="text-gray-800 text-[16px] leading-relaxed">
            Asendia reserves the right to modify, update, or change these Terms of Service at any time without prior notice. Changes will become effective immediately upon posting the revised Terms of Service on the Asendia platform.
          </p>
          <p className="mt-4 text-gray-800 text-[16px] leading-relaxed">
            Asendia will make reasonable efforts to notify users of material changes, such as through email or prominent notices on the platform. However, it is the Worker's responsibility to review the Terms of Service periodically for updates. Continued use of the platform after changes have been posted constitutes the Worker's acceptance of the revised Terms of Service. If the Worker does not agree to the updated Terms of Service, they must discontinue using the Asendia platform.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkersTerms;
