// JobsData.js
export const JobsData = [
    {
      title: 'Future jobs',
      slug: 'future-jobs',
      type: 'Part & Full-Time',
      // These fields can be used if you want to display something,
      // or you can leave them blank. In this example, we use a custom description.
      salary: 'Competitive',
      location: 'Worldwide',
      hours: 'Flexible',
      // A custom description to be displayed in the card (instead of location/hours/salary)
      description:
        "We regularly post new jobs on Asendia.ai! If you don’t see the perfect role today, no worries, apply anyway! Our AI-powered matching algorithm will automatically consider you for future opportunities. By applying now, you increase your chances of being selected, as your profile will be ready to match the moment the right job opens up. 🚀",
      sections: []
    },
    {
      title: 'Lead Developer AI/NLP (m/f/d)',
      slug: 'lead-developer-ai-nlp',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Technology | Full-time | Garching near Munich, Germany',
      postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: 'YOUR ROLE',
          paragraphs: [
            'Ownership of our multiple AI models along our explainer videos generation pipeline.',
            "Improving the company's data, leveraging foundational models, and/or applying the newest finetuning techniques to develop innovative and leading explainer video-generation capabilities.",
            'Integrating traditional NLP techniques to mitigate hallucination and increase the predictability of output.',
            'Combining CV and NLP techniques.',
            'After successful onboarding: lead a small AI team (80% Individual Contributor / 20% Management).',
            'Close collaboration with the CTO.'
          ]
        },
        {
          title: 'SKILLS AND EXPERTISE',
          bullets: [
            'Experience as a professional AI Engineer, Data Analyst, Applied Research Scientist, or similar role.',
            'Data analytics skills to continuously evaluate and improve model output.',
            '2+ years of experience in Python and Huggingface.',
            'Strong NLP knowledge from traditional techniques to transformers, plus a basic understanding of Computer Vision.',
            'Native German speaker.',
            'Availability on-site in Garching near Munich.',
            'Experience with the following tools and frameworks is beneficial: PyTorch, Azure ML Studio, Optuna, Langchain, Peft & Lora, Bitsandbytes, Scikit, NLTK, Numpy, Pandas, etc.'
          ]
        },
        {
          title: 'YOUR BENEFITS',
          paragraphs: [
            'Be part of an innovative and dynamic team in a growing tech startup.',
            "Directly influence the company's success and product development.",
            'Collaborate closely with the CTO and the other co-founders.',
            'Option to participate in the company’s success through virtual shares (VSOPs).',
            'Contribute to building an innovative, leading AI-based video generation backend leveraging various state-of-the-art ML/NLP/CV technologies.',
            'A creative and inspiring work environment.'
          ]
        }
      ]
    },
    {
      title: 'Lead Developer Frontend (m/f/d)',
      slug: 'lead-developer-frontend',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Technology | Full-time | Garching near Munich, Germany',
    postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: 'YOUR ROLE',
          paragraphs: [
            'Ownership of our web application frontend that enables customers to manage, create, and edit videos.',
            'Ensure maintainability through solid architecture, clean code, and refactoring existing components.',
            'Define, design, and implement UX in a customer-centric way – from Figma to production.',
            'Enhance video editability with state-of-the-art capabilities incorporating our AI services.',
            'After successful onboarding: lead a small frontend team (80% Individual Contributor / 20% Management).',
            'Close collaboration with the CTO.'
          ]
        },
        {
          title: 'SKILLS AND EXPERTISE',
          bullets: [
            '2+ years of experience as a Frontend Developer, UI/UX Engineer, Software Developer, or similar.',
            'Proven track record of contributing to a mid- to large-scale web application project.',
            '2+ years of experience in React and TypeScript/JavaScript.',
            'User-centric thinking with a focus on UX and a strong sense of aesthetics/visuals.',
            'Experience with Figma or Adobe XD.',
            'Availability on-site in Garching near Munich.',
            'Experience with the following is beneficial: Tailwind, CI/CD practices, Microsoft Azure.'
          ]
        },
        {
          title: 'YOUR BENEFITS',
          paragraphs: [
            'Be part of an innovative and dynamic team in a growing tech startup.',
            "Directly influence the company's success and product development.",
            'Collaborate closely with the CTO and the other co-founders.',
            'Option to participate in the company’s success through virtual shares (VSOPs).',
            'Contribute to building an innovative, leading AI-based video generation backend leveraging various state-of-the-art ML/NLP/CV technologies.',
            'A creative and inspiring work environment.'
          ]
        }
      ]
    },
    {
      title: '(Senior) AI Engineer',
      slug: 'senior-ai-engineer',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Germany',
    postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: 'YOUR ROLE AS AN AI ENGINEER',
          paragraphs: [
            'As a (Senior) AI Engineer, you play a key role in designing and implementing state-of-the-art AI solutions, are responsible for the entire AI lifecycle—from data ingestion and transformation to building, deploying, and optimizing machine learning models—and work closely with data platform teams to ensure that AI-driven insights translate into tangible business value, all while maintaining a strong focus on innovation, scalability, and operational excellence by staying up to date with the latest AI advancements (e.g., Multiagent-Architectures) and cloud technologies on Microsoft Azure.'
          ]
        },
        {
          title: 'YOUR RESPONSIBILITIES',
          bullets: [
            'End-to-End AI Development – Build and optimize AI models, covering the entire lifecycle from data ingestion to deployment and operations.',
            'Machine Learning & Advanced Analytics – Apply statistical and ML concepts to solve business challenges, including predictive analytics, optimization, and NLP.',
            'Generative AI - Build, integrate and optimize Large Language Models (LLM), including Text-to-Speech (TTS) and Speech-to-Text (STT).',
            'Data Engineering & Pipelines – Ensure data quality by handling ingestion, preparation, and transformation to create a solid foundation for AI use cases.',
            'AI Deployment & Operations – Implement scalable and production-ready AI solutions using cloud-based MLOps best practices.',
            'AI Innovation – Stay up-to-date with the latest advancements in AI and cloud technologies, and evaluate their potential for integration.',
            'Customer Enablement & Integration – Collaborate with cross-functional teams to customize AI tools and embed them into business processes for maximized value.',
            'Compliance & Governance - Ensure compliance with data privacy, security, and ethical AI guidelines in all solutions.'
          ]
        },
        {
          title: 'WHAT YOU BRING TO THE TABLE',
          bullets: [
            '3+ years of experience – AI engineering, data science, or machine learning, ideally with data engineering expertise.',
            'End-to-end AI expertise – Expertise in data preparation, feature engineering, model training, deployment, and continuous optimization.',
            'Strong Python skills – Proficient in ML frameworks and cloud-based AI platforms like Azure ML and Databricks.',
            'Cloud & AI ecosystem knowledge – Familiar with Microsoft Azure Data & AI services and leading AI platforms/APIs such as OpenAI, Gemini, and Anthropic.',
            'MLOps & production deployment – Hands-on experience with MLOps tooling (Azure DevOps, GitHub, GitLab) and deploying AI solutions in production.',
            'AI infrastructure expertise – Skilled in designing and deploying AI infrastructure, including containerization with Docker.',
            'Broad AI/ML understanding – Strong foundation in NLP, computer vision, and conversational AI (e.g., Azure Bot Services, Microsoft Copilot).',
            'Experience with AI models – Familiar with LLMs, TTS, and STT technologies.',
            'Awareness of AI regulations – Understanding of data privacy regulations (e.g., GDPR) and their implications for AI projects.',
            'Customer interaction & leadership – Comfortable engaging with stakeholders and leading workshops.',
            'Innovative & team-oriented mindset – Passion for AI, proactive attitude, and collaborative work style.',
            'Excellent communication skills – Fluent in English (C2) and German (C1).'
          ]
        },
        {
          title: 'EDUCATION',
          paragraphs: [
            'Bachelor’s or master’s degree in computer science, artificial intelligence, or a related field.'
          ]
        },
        {
          title: 'WHY JOIN US?',
          paragraphs: [
            'Exciting AI projects - with cutting-edge technologies – work with Azure, Databricks, OpenAI, and more.',
            'High-performance culture – we’re looking for “Doers” who want to make an impact.',
            'Fast learning & career growth – benefit from top-tier training and certifications.',
            'Innovative company culture – we embrace hands-on mentality, team spirit, and AI-driven excellence.',
            'Broad ecosystem – as part of our DNA, we cultivate an exceptional network and work closely with partners and startups.'
          ]
        }
      ]
    },
    {
      title: '(Senior) Data Engineer',
      slug: 'senior-data-engineer',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Germany',
    postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: 'YOUR ROLE',
          paragraphs: [
            "To support the growth of our expanding operations, we are looking for skilled mid and senior Data Engineers eager to pioneer impactful data pipelines using the Microsoft Tech Stack. If you’re passionate about crafting cloud-based data solutions and, more importantly, understanding our clients' businesses to create solutions that drive real impact, this role is for you!"
          ]
        },
        {
          title: 'WHAT YOU’LL GET TO DO',
          paragraphs: [
            'Collaborate closely with business and tech experts to understand client requirements and deliver cloud-based Data Warehouse solutions using Azure services like Azure Data Lake Storage, Azure SQL Database, Azure Synapse Analytics, and Azure Databricks.',
            'Develop and optimize data pipelines and models for maximum performance and efficiency across the entire data ecosystem.',
            'Build, optimize, and maintain ETL processes using cutting-edge tools like Databricks, Spark, and Kafka.',
            'Implement and maintain CI/CD pipelines to automate testing and deployment, ensuring the reliability and scalability of data engineering solutions.',
            'Apply data and software engineering best practices to enhance the functionality, performance, and scalability of the underlying data platform.'
          ]
        },
        {
          title: 'TECHNOLOGIES WE WORK WITH',
          bullets: [
            'Microsoft Azure',
            'Azure Synapse Analytics',
            'Microsoft Purview',
            'Microsoft Fabric',
            'Azure Cosmos DB',
            'Databricks',
            'OpenAI',
            'Apache Spark',
            'Delta Lake'
          ]
        },
        {
          title: 'WHAT YOU BRING TO THE TABLE',
          bullets: [
            'Experience: Several years as a Data Engineer or in a similar role, ideally in a client-facing and data-driven environment.',
            'Technical Skills: Hands-on experience in data pipeline development with Big Data frameworks (Databricks, Spark, Kafka), data modeling approaches (Star Schema, Snowflake, Data Vault), and Azure Services (Azure Data Factory, Azure Databricks, and Azure Synapse Analytics).',
            'Collaboration: Enjoy working in an interdisciplinary team alongside experienced business and technical colleagues.',
            'Passion for Data: Excited about leveraging data to drive business impact. You care about code quality, simplicity, and performance.',
            'Language Skills: Working proficiency in English (C1). German skills (C1) are a strong plus.'
          ]
        },
        {
          title: 'BENEFITS & GROWTH',
          paragraphs: [
            '30 days vacation',
            'Hybrid working model',
            'Modern central offices in Munich, Cologne, and Lisbon',
            'Full support for professional training & certifications (Microsoft, SAFe)',
            'Friendly & inclusive international workplace culture',
            'Access to state-of-the-art technology',
            'Company events & team activities',
            'Employer-supported pension plan (BaV)',
            'Discounted travel options (Deutschland-Ticket, BahnCard for frequent travelers)',
            'Strong industry partnerships (Microsoft, SAFe, Databricks)'
          ]
        }
      ]
    },
    {
      title: '(Senior) Data Solution Architect',
      slug: 'senior-data-solution-architect',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Germany',
    postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: ' Become our new (Senior) Data Solution Architect (m/f/d) – and shape the future of data-driven platforms!',
          paragraphs: [
            'Are you passionate about data? Do you want to help companies build scalable and secure data & AI platforms on Microsoft Azure and Databricks? Are you ambitious, solution-oriented, and love working with cutting-edge technologies? Then join our team!',
            "As a Data Solution Architect, you are responsible for the conception and technical design of data platforms and the data & (Gen) AI use cases based on them. You ensure that the architecture is scalable, secure, and efficient and oversee implementation until go-live. You also work closely with business and technical teams to translate strategic business requirements into powerful data & AI solutions."
          ]
        },
        {
          title: 'YOUR TASKS & RESPONSIBILITIES',
          bullets: [
            'Architecture Design & Roadmap: Conception of Data & AI Platforms on Microsoft Azure.',
            'Technology Selection: Identification and integration of suitable Azure services (e.g., Azure Data Lake, Azure Machine Learning, Azure OpenAI, Microsoft Fabric, Databricks).',
            'Data Architecture & Modeling: Development of data architecture (e.g., data lakehouse, data mesh, metadata management, governance & security) and modeling concepts (including Star/Snowflake Schema, Data Vault) in collaboration with our data consultants and analytics engineers.',
            'Security & Governance: Integration of data governance & security (e.g., Unity Catalog, Role-Based Access Control, PII handling).',
            'DataOps & CI/CD: Automation, monitoring, and implementation of Infrastructure-as-Code (e.g., using Terraform, Bicep, ARM).',
            'Scalability & Performance: Ensuring performance, cost efficiency, and future viability.',
            'Collaboration with Engineering & Product Teams: Work hands-on as part of Data Product or Platform Teams on Data & AI solutions.',
            'Proof of Concept (PoCs) & MVPs: Implementation of technical feasibility studies & support in pre-sales.',
            'Technical Coaching: Support and empower teams with best practices and standards in cloud architecture & Databricks technologies.',
            'Consulting & Customer Interaction: Act as a “trusted advisor” for customers and identify up-/cross-selling opportunities.',
            'Customer Workshops & Presentations: Conduct whiteboard sessions and presentations, including at senior management level.'
          ]
        },
        {
          title: 'WHAT YOU BRING – YOUR SKILLS & EXPERIENCE',
          bullets: [
            'Cloud & Data Architecture: 3+ years of experience in cloud architecture and working with technologies such as Azure Data Services, Microsoft Fabric, and Databricks.',
            'Data & AI: 5+ years of experience in data analytics, business intelligence, or AI.',
            'Data Architecture & Concepts: In-depth knowledge of concepts such as Data Lakehouse, Data Warehousing (Star/Snowflake Schema), AI/Machine Learning.',
            'Big Data & Streaming Technologies: Experience with Kafka/Event Hub, Delta Live Tables, Spark Structured Streaming.',
            'Security & Governance: Expertise in Azure Purview, Unity Catalog, Access Management, Metadata Management.',
            'DevOps & Automation: Experience with CI/CD pipelines and infrastructure-as-code (Terraform, Bicep, ARM).',
            'Communication Skills: Ability to explain complex technical concepts clearly to business & IT teams. Confident in presenting results to senior stakeholders.',
            'Hands-on Mentality: Passion for operationally shaping projects and driving forward solutions.'
          ]
        },
        {
          title: 'CERTIFICATIONS',
          bullets: [
            'Microsoft Azure Solutions Architect Expert',
            'Microsoft Fabric Analytics Engineer',
            'Databricks Certified Data Engineer Professional'
          ]
        },
        {
          title: 'EDUCATION',
          paragraphs: [
            'Bachelor’s degree in (business) information technology, engineering, or a related field.'
          ]
        },
        {
          title: 'LANGUAGE SKILLS',
          paragraphs: [
            'Fluent and business-level proficiency in German and English.'
          ]
        },
        {
          title: 'YOUR ROLE IN THE PROJECT – CONTRIBUTION & RESPONSIBILITIES',
          bullets: [
            'Pre-Sales → Act as a “trusted advisor,” conduct PoCs & MVPs, collaborate with partners (including Microsoft), and support sales with technical scoping & effort estimations.',
            'Concept → Analyze business requirements, define architecture, create solution paths, backlogs, and cost/effort estimates.',
            'Build → Oversee technical implementation, conduct architecture reviews, performance tuning, testing & documentation.',
            'Go-Live → Conduct User Acceptance Tests (UAT), create Ops Handbooks, and enablement of teams.'
          ]
        },
        {
          title: 'WHY YOU’RE IN THE RIGHT PLACE WITH US',
          paragraphs: [
            '🚀 Exciting data & AI projects – Work with Azure, Databricks & leading AI technologies.',
            '🎯 High-performance & ambition – We are looking for doers who want to make an impact.',
            '📈 Steep learning curve & development – Join a top team with regular training & certifications.',
            '🤝 Innovative corporate culture – We foster a hands-on mentality, team spirit & data-driven excellence.',
            '🌍 Broad ecosystem – We maintain an exceptional network and collaborate closely with partners and startups.'
          ]
        }
      ]
    },
    {
      title: '(Senior) Technical Product Manager - Data & AI',
      slug: 'senior-technical-product-manager-data-ai',
      type: 'Full-time',
      salary: '', // Salary not specified
      location: 'Onsite | Germany',
    postedDate: '2025-03-11T15:00:00Z',
      hours: 'Full-time',
      sections: [
        {
          title: '🚀 Become our new (Senior) Technical Product Manager - Data & AI (m/f/d).',
          paragraphs: [
           'Are you passionate about data? Do you want to help companies orchestrate innovative data & AI use cases and develop scalable platforms on Microsoft Azure and Databricks? Are you ambitious, solution-oriented, and love working at the interface between business and technology? Then join our team!'
          ]
        },
        {
          title: 'YOUR ROLE AS (SENIOR) TECHNICAL PRODUCT MANAGER - DATA & AI',
          paragraphs: [
            "As a Technical Product Manager, you will be the central interface between business and technology and will be responsible for prioritization and the functional and technical management of the project. Your focus will be on the orchestration of data & AI use cases and the development and further enhancement of scalable data platforms. With a clear view of business value, you ensure that all requirements are implemented efficiently and that the solutions developed create measurable added value. The Product Manager assumes end-to-end responsibility for the product and works closely with the customer and the entire implementation team to realize innovative and future-proof solutions."
          ]
        },
        {
          title: 'YOUR TASKS & RESPONSIBILITIES',
          bullets: [
            'Product strategy & roadmap: Define the product vision and derive the product roadmap.',
            'Requirements management: Translate business requirements into technical specifications for data & AI use cases and the data platform.',
            'Product backlog management: Build and prioritize the product backlog, considering business value, technical feasibility, dependencies, and blockers.',
            'Development & scaling: Responsible for building MVPs and developing them into scalable products and platforms.',
            'Product teams: Work closely with Data Solution Architects, Data Engineers, and AI Engineers to implement data-driven solutions.',
            'Development methods: Ensure efficiency through Scrum, Kanban, DevOps, and SRE principles.',
            'Team & meeting coordination: Organize meetings and agile ceremonies with the team and the customer.',
            'Success measurement & optimization: Use KPIs and OKRs to continuously improve solutions.',
            'Compliance & data protection: Ensure regulatory compliance (e.g., GDPR, HIPAA) for data-driven solutions.',
            'Sustainability & scalability: Guarantee the long-term maintainability and scalability of developed solutions.',
            'Project responsibility: Manage the team and budget for successful implementation.',
            'Stakeholder management: Coordinate with business departments and management on status, risks, and new opportunities.'
          ]
        },
        {
          title: 'WHAT YOU BRING – YOUR SKILLS & EXPERIENCE',
          bullets: [
            'Product discovery & development: Experience with methods like Scrum, Kanban, DevOps, and SRE, as well as market-leading ticket systems and wikis (Jira, Confluence, Azure DevOps).',
            'Business value orientation: Ability to derive business value and impact, aligning the roadmap and product backlog accordingly.',
            'Hands-on mentality: A proactive approach to shaping and advancing products.',
            'Technical understanding of data & AI platforms: Experience with cloud data platforms (preferably Microsoft Azure) and analytics/AI use cases.',
            'Data architectures and concepts: Basic knowledge of data warehouses, data lakehouses, self-service BI, and MLOps.',
            'Stakeholder management & communication: Ability to explain complex technical concepts to management, business, and IT teams.'
          ]
        },
        {
          title: 'EDUCATION',
          paragraphs: [
            "Bachelor's degree in (business) informatics, business administration with a technical focus, or a related field."
          ]
        },
        {
          title: 'LANGUAGE SKILLS',
          paragraphs: [
            'Fluent in both German and English (business-level).'
          ]
        },
        {
          title: 'YOUR ROLE IN THE PROJECT – CONTRIBUTION AND RESPONSIBILITIES',
          bullets: [
            'Strategy & objectives: Define overall project objectives and key success metrics.',
            'Discovery & conception: Identify customer segments, customer journeys, and key problem/solution areas.',
            'Development & implementation: Ensure efficient execution of technical and business requirements.',
            'Go-live & operations: Conduct UATs, coordinate final acceptance, and provide hypercare support.',
            'Project organization: Manage resources and budget while ensuring transparent communication through regular status reports.',
            'Up- & cross-selling potential: Identify new expansion opportunities and coordinate with account managers.',
            'Project Manager – Define product goals and ensure maximum business value.',
            'Business Owner – Work closely with customer stakeholders to refine requirements and acceptance criteria.'
          ]
        },
        {
          title: 'WHY JOIN US?',
          paragraphs: [
            '🚀 Exciting data & AI projects – Work with Azure, Fabric, Databricks, and leading cloud technologies.',
            '🎯 High-performance & ambition – We’re looking for doers who want to make an impact.',
            '📈 Steep learning curve & development – Join a top team with regular training & certifications.',
            '🤝 Innovative corporate culture – We foster a hands-on mentality, team spirit, and data-driven excellence.'
          ]
        }
      ]
    },
    {
      title: 'Senior Software Engineer',
      slug: 'senior-software-engineer',
      type: 'Part & Full-Time',
      salary: '', // Salary not mentioned for now
      location: 'Remote (Worldwide)',
      postedDate: '2025-02-14T01:11:00Z',
      hours: '10–20 hours per week (with potential to increase to 40)',
      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "Step into a dynamic, creative environment where AI innovation thrives. We're working with leading AI companies in the UK, US, and Canada on exciting projects pushing the boundaries of technology. As a Senior Software Engineer, you'll play a key role in designing, testing, and refining cutting-edge AI models that make a real-world impact.",
            "This role goes beyond standard development—it’s about leading experiments, optimizing solutions, and shaping the future of AI. You’ll be part of a team that values creativity, collaboration, and continuous learning."
          ]
        },
        {
          title: 'Our Simple Selection Process',
          bullets: [
            "Apply: Submit your latest resume.",
            "Mock Interview: Participate in a quick mock interview to showcase your skills. (No Coding Needed)",
            "Decision: We’ll review your application and notify you of the outcome within a few weeks."
          ]
        },
        {
          title: 'Who You Are',
          bullets: [
            "A degree in Computer Science or a related field.",
            "3+ years of software engineering experience, with a strong background in AI, machine learning, or backend development.",
            "A problem-solver who thrives on innovation and technical challenges.",
            "Strong attention to detail and excellent communication skills."
          ]
        },
        {
          title: 'Why You’ll Love Working Here',
          bullets: [
            "Impactful Projects: Work on technology shaping industries and businesses.",
            "Flexibility: Set your own schedule and work fully remote.",
            "Growth Potential: Collaborate with top-tier AI teams and stay ahead in cutting-edge development."
          ]
        }
      ]
    },    
    {
      title: 'Junior Software Engineer',
      slug: 'junior-software-engineer',
      type: 'Part-time',
      salary: '$50 - $100 per hour (based on experience)',
      location: 'Remote (Worldwide)',
      postedDate: '2025-02-13T00:00:00Z',
      hours: '10–20 hours per week (with potential to increase to 40)',
      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "Step into a dynamic, creative environment where AI innovation thrives. We're working with leading AI companies in the UK, US, and Canada on exciting projects pushing the boundaries of technology. As a Junior Software Engineer, you'll have the opportunity to test, evaluate, and improve cutting-edge AI models in ways that make a real-world impact.",
            "This role isn't just about running tests—it’s about experimenting with ideas, refining solutions, and being part of the AI evolution. You’ll be part of a team that values creativity, collaboration, and continuous learning."
          ]
        },
        {
          title: 'Our Simple Selection Process',
          bullets: [
            "Apply: Submit your latest resume.",
            "Mock Interview: Participate in a quick mock interview to showcase your skills. (No Coding Needed)",
            "Decision: We’ll review your application and notify you of the outcome within a few weeks."
          ]
        },
        {
          title: 'Who You Are',
          bullets: [
            "A degree in Computer Science or related field from a university in the U.S., UK, or Canada.",
            "1+ years of software engineering experience (including internships).",
            "A curious mind that thrives on problem-solving and exploration.",
            "Strong attention to detail and excellent communication skills."
          ]
        },
        {
          title: 'Compensation & Work Setup',
          paragraphs: [
            "Pay: $50–$100/hour based on experience.",
            "Contract: Hourly, with weekly payments via Stripe.",
            "Hours: 10–20 hours per week (with room to increase to 40 hours).",
            "Start Date: Immediate upon selection."
          ]
        },
        {
          title: 'Why You’ll Love Working Here',
          bullets: [
            "Creative Work Culture: Your ideas and fresh perspectives are encouraged.",
            "Impactful Projects: You'll be working on technology that affects how businesses operate and innovate.",
            "Flexibility: Set your own schedule and work at your pace, fully remote.",
            "Growth Potential: Learn from experienced tech teams and gain hands-on experience in building tomorrow's tech."
          ]
        },
        {
          title: 'Refer a Friend, Earn Big! 🚀',
          paragraphs: [
            "Got a friend, colleague, or connection who would crush this role? Don’t keep it to yourself! Send them our way and earn $300 for every successful referral."
          ]
        }
      ]
    },
    {
      title: 'Graduate Opportunities at Leading AI Startups',
      slug: 'graduate-opportunities-leading-ai-startups',
      type: 'Entry-Level',
      salary: '', // Salary not specified
      location: 'UK (Remote & Hybrid Roles Available)',
      postedDate: '2025-02-25T00:00:00Z',
      hours: 'Flexible',
      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "Asendia AI is hiring recent graduates for some of the most exciting AI startups in the UK! We’re looking for ambitious individuals across AI & software engineering, marketing, finance, operations, product, and more. Whether you're technical or business-focused, this is a great opportunity to launch your career in AI."
          ]
        },
        {
          title: 'What You’ll Do',
          bullets: [
            "Work on cutting-edge AI products and technologies",
            "Collaborate with top AI teams and founders",
            "Gain hands-on experience in a fast-paced startup environment"
          ]
        },
        {
          title: 'Who We’re Looking For',
          bullets: [
            "Recent graduates",
            "Passion for AI and startups",
            "Strong problem-solving and communication skills"
          ]
        },
        {
          title: 'What We Offer',
          bullets: [
            "Competitive salary",
            "Career growth opportunities in AI startups",
            "Exposure to innovative AI projects"
          ]
        },
        {
          title: 'How to Apply',
          paragraphs: [
            "Click “Apply” → Take a 15-minute AI interview. Once you complete the interview, you’ll join the Asendia talent pool and be considered for current and future roles with our AI startup partners."
          ]
        }
      ]
    },
    
    {
      title: 'Founding Engineer',
      slug: 'founding-engineer',
      type: 'Full-time',
      salary: '$80K – $120K + stock awards (ESOP)',
      location: 'Remote (Worldwide)',
      hours: 'Full-time',
      postedDate: '2025-02-13T00:00:00Z',

      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "Step into a pivotal role at an early-stage startup where innovation and AI intersect. We're partnering with some of the fastest-growing companies in the U.S. and UK that have just raised Seed to Series A funding. These startups are developing AI-driven solutions across industries such as Fintech, HRTech, and EdTech.",
            "As a Founding Engineer, you’ll take full ownership of technical development and work directly with startup founders to shape product and technology strategies. You’ll have the opportunity to define the product roadmap, build scalable infrastructure, and make a significant impact on each startup’s success. We’re looking for someone who’s excited to work in a fast-moving environment, thrives on solving complex challenges, and embraces responsibility and ownership."
          ]
        },
        {
          title: 'You Are a Good Fit If You:',
          bullets: [
            "Have deep expertise in one or more of the following: Python, Node.js, TypeScript, React, or other modern tech stacks.",
            "Have 3+ years of industry experience working directly in full-stack development (or equivalent experience in startup or product-focused roles).",
            "Have experience with cloud platforms (Azure preferred, AWS or GCP acceptable), microservices, and Kubernetes."
          ]
        },
        {
          title: 'Compensation & Benefits',
          paragraphs: [
            "Salary: $80K – $120K based on experience.",
            "Stock Awards: Equity via ESOP, allowing you to share in the startups’ long-term success.",
            "Location: Fully remote role working with U.S. and UK-based startups.",
            "Start Date: Immediate upon selection."
          ]
        },
        {
          title: "Why You'll Love Working Here",
          bullets: [
            "High-Impact Work: Build products that directly shape the success of early-stage startups and their technology.",
            "Ownership: Drive technical decisions and product direction at the foundational stage of multiple startups.",
            "Growth Potential: Collaborate with startup founders and advance your career through exposure to cutting-edge AI and scalable systems.",
            "Flexible Work: Fully remote, with autonomy over your schedule and work style.",
            "Creative Culture: Work with dynamic, fast-moving teams that value creativity, innovation, and learning."
          ]
        },
        {
          title: 'Our Simple Selection Process',
          bullets: [
            "Apply: Submit your latest resume.",
            "Mock Interview: Participate in a quick mock interview to showcase your skills. (No Coding Needed)",
            "Decision: We’ll review your application and notify you of the outcome within a few weeks."
          ]
        },
        {
          title: 'Refer a Friend, Earn Big! 🚀',
          paragraphs: [
            "Know someone who’d thrive in this role? Refer them and earn $500 for every successful hire!"
          ]
        }
      ]
    },
    {
      title: 'Business Strategy Consultant',
      slug: 'business-strategy-consultant',
      type: 'Part-time',
      salary: '$50 – $100 per hour (based on experience)',
      location: 'Remote (Worldwide)',
      postedDate: '2025-02-13T00:00:00Z',

      hours: '10–20 hours per week (with potential for more based on project needs)',
      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "We’re hiring MBA students and graduates to partner with top AI companies and startups in the U.S., UK, and Canada. This role offers $50–100 USD/hour with flexible, remote work.",
            "You’ll support founders and business leaders by providing strategic insights, market research, and process improvements, helping shape next-generation AI solutions."
          ]
        },
        {
          title: 'You Might Be a Great Fit If You:',
          bullets: [
            "Have worked in roles like: Strategy or Management Consulting, Corporate Finance/Investment Banking, Operations/Business Strategy, Marketing/Sales Leadership, Business Development, Recruitment or Talent Strategy, Project or Program Management.",
            "Are skilled at conducting in-depth research, analyzing data, and crafting strategic recommendations.",
            "Excel at working across teams in fast-paced, innovative environments."
          ]
        },
        {
          title: 'What to Expect',
          paragraphs: [
            "Collaborate with AI experts and startup founders on high-impact projects.",
            "Work is remote, asynchronous, and flexible (10–20 hours/week).",
            "Receive weekly payouts through Stripe."
          ]
        },
        {
          title: 'Refer a Friend, Earn Big! 🚀',
          paragraphs: [
            "Know someone who’d thrive in this role? Refer them and earn $300 for every successful hire!"
          ]
        }
      ]
    },
    {
      title: 'Student Opportunities – (Internship)',
      slug: 'student-opportunities-internship',
      type: 'Part & Full-Time',
      salary: '$15 – $50 per hour (based on experience)',
      location: 'Remote (Worldwide)',
      postedDate: '2025-02-13T00:00:00Z',

      hours: '10–40 hours per week',
      sections: [
        {
          title: 'About the Role',
          paragraphs: [
            "We’re working with top AI companies and startups in the U.S., UK, and Canada. As an intern, you’ll gain hands-on experience in software development, contributing to real-world AI projects. You’ll collaborate with experienced engineers to build, test, and improve innovative technologies."
          ]
        },
        {
          title: 'You Might Be a Great Fit If You:',
          bullets: [
            "Are a student or recent graduate in Computer Science or a related field.",
            "Have basic coding experience (Python, JavaScript, or other programming languages).",
            "Are eager to learn and curious about AI."
          ]
        },
        {
          title: 'Role Details',
          bullets: [
            "Write code, test features, and work on development tasks.",
            "Work is remote and flexible (10–40 hours per week).",
            "Project length is 1–2 months with possible extensions.",
            "Payments are made weekly via Stripe Connect."
          ]
        },
        {
          title: 'Refer a Friend, Earn Big! 🚀',
          paragraphs: [
            "Know someone who’d thrive in this role? Refer them and earn $125 for every successful hire!"
          ]
        }
      ]
    }


    
  ];
  