export function TimeAgo(postedDate) {
  const currentDate = new Date();
    const postDate = new Date(postedDate);

    // Ensure postedDate is valid
    if (isNaN(postDate.getTime())) {
        return "Invalid date";
    }

    let diffTime = Math.floor((currentDate - postDate) / 1000); // Convert to seconds

    if (diffTime < 0) {
        return "Posted 1 hour ago"; // Prevents negative values for future dates
    }

    const minutes = Math.floor(diffTime / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `Posted ${days} day${days !== 1 ? "s" : ""} ago`;
    if (hours > 0) return `Posted ${hours} hour${hours !== 1 ? "s" : ""} ago`;
    if (minutes > 0) return `Posted ${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    return "Posted 1 hour ago";
}
// Utility function to get time ago
