import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import "../../index.css";

// Loading Component
const LoadingPlaceholder = () => (
  <div className="h-screen w-full flex items-center justify-center bg-white">
    <div className="spinner"></div>
  </div>
);

// Lazy-loaded Components
const HeaderSection = lazy(() => import("./HeaderSection/HeaderSection"));
const TrustedBySlider = lazy(() => import("./TrustedBySlider/TrustedBySlider"));
const HiringProcess = lazy(() => import("./HiringProcess/HiringProcess"));
const PitchYourSelf = lazy(() => import("./PitchYourSelf/PitchYourSelf"));
const FeaturesSection = lazy(() => import("./OurFeatures/OurFeatures"));
const Templates = lazy(() => import("./TemplatesSection/Templates"));
const Testimonial = lazy(() => import("./Testimonial/Testimonial"));
const GlobalTalentsSection = lazy(() =>
  import("../../Components/NewsLetter/NewsLetter")
);

const ForJobSeekers = () => {
  return (
    <>
      <Helmet>
        <title>Job Seekers ─ Where Global Talent Meets Opportunity</title>
        <meta
          name="description"
          content="Unlock your potential with Asendia AI. Practice AI-driven mock interviews, receive personalized feedback, and build an impressive profile to attract leading recruiters worldwide."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Job Seekers – Where Global Talent Meets Opportunity" />
        <meta
          property="og:description"
          content="Where Global Talent Meets Opportunity: Prepare for interviews, showcase your strengths, and connect with global opportunities effortlessly."
        />
        <meta property="og:url" content="https://www.asendia.ai/job-seekers" />
        <meta
          property="og:image"
          content="https://www.asendia.ai/images/social_sharing_banner2.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Job Seekers – Where Global Talent Meets Opportunity" />
        <meta
          name="twitter:description"
          content="Asendia AI helps job seekers succeed with AI-driven tools. Pitch yourself, prepare for interviews, and connect with recruiters globally."
        />
        <meta
          name="twitter:image"
          content="https://www.asendia.ai/images/social_sharing_banner2.png"
        />
        
      </Helmet>



      <main className="max-w-[1340px] h-auto mx-auto flex flex-col max-sm:p-[10px]">
        <Suspense fallback={<LoadingPlaceholder />}>
          {/* Header Section */}
          <HeaderSection />

          {/* Trusted By Slider */}
          {/* <div className="separator my-10 w-[80%] mx-auto h-[1px]" aria-hidden="true"></div>
          <TrustedBySlider /> */}

          {/* Hiring Process */}
          <div className="separator my-14 w-[80%] mx-auto h-[1px]" aria-hidden="true"></div>
          <HiringProcess />

          {/* Pitch Yourself */}
          <div className="separator my-10 w-[80%] mx-auto h-[1px]" aria-hidden="true"></div>
          <PitchYourSelf />

          {/* Features */}
          <div className="separator my-14 w-[80%] mx-auto h-[1px]" aria-hidden="true"></div>
          <FeaturesSection />

          {/* Templates */}
          <Templates />

          {/* Testimonials */}
          <Testimonial />

          {/* Global Talents */}
          <div className="separator my-14 w-[80%] mx-auto h-[1px]" aria-hidden="true"></div>
          <GlobalTalentsSection />
        </Suspense>
      </main>
    </>
  );
};

export default ForJobSeekers;