// JobsPage.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { JobsData } from './components/JobsData';
import { Zap, Clock, MapPin, ArrowRight, Gift, Search, ChevronDown, SlidersHorizontal } from 'lucide-react';

// Animation variants for items
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
};

// A simple badge component based on job type
const Badge = ({ type }) => {
  const getColorClasses = () => {
    switch (type) {
      case 'Full-time':
        return 'bg-blue-50 text-blue-700 border border-blue-200';
      case 'Part-time':
        return 'bg-green-50 text-green-700 border border-green-200';
      case 'Part & Full-Time':
        return 'bg-purple-50 text-purple-700 border border-purple-200';
      default:
        return 'bg-gray-50 text-gray-700 border border-gray-200';
    }
  };

  return (
    <span className={`w-fit px-3 py-1 rounded-full text-xs font-medium shrink-0 ml-auto ${getColorClasses()}`}>
      {type}
    </span>
  );
};

// Utility function to calculate time difference
const getTimeSincePosted = (postedDate) => {
  if (!postedDate) return null; // Return null if no postedDate is provided

  const now = new Date();
  const posted = new Date(postedDate);
  const diffInMs = now - posted;
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
};

const JobCard = ({ job, onApply }) => {
  const navigate = useNavigate();
  const isFutureJob = job.slug === 'future-jobs';

  // Get time since posted
  const timeSincePosted = getTimeSincePosted(job.postedDate);

  // Define two different button styles:
  const defaultButtonClass =
    'w-full flex items-center justify-center gap-2 bg-indigo-700 text-white py-2.5 px-4 rounded-xl font-semibold hover:bg-indigo-800 transition-all duration-200 hover:shadow-lg';
  const futureButtonClass =
    'w-full flex items-center justify-center gap-2 bg-gradient-to-r from-indigo-700 to-purple-600 text-white py-2.5 px-4 rounded-xl font-semibold hover:bg-indigo-800 transition-all duration-200 hover:shadow-lg';

  return (
    <div className="group relative bg-white rounded-2xl shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100 overflow-hidden flex flex-col h-full">
      <div className="flex flex-col flex-grow p-6 relative z-10">
        <div>
          {/* Job Title and Badge */}
          <div className="flex justify-between items-start gap-4 mb-4">
            <div>
              <h2 className="text-xl font-bold text-gray-900">{job.title}</h2>
              {/* Show time since posted if available */}
              {timeSincePosted && (
                <p className="text-gray-500 text-sm mt-1">Posted {timeSincePosted}</p>
              )}
            </div>
            <Badge type={job.type} />
          </div>

          {/* Job details */}
          {isFutureJob ? (
            <p className="text-gray-600 font-normal">{job.description}</p>
          ) : (
            <div className="space-y-4 text-sm">
              <div className="flex items-center gap-2 text-gray-600">
                <MapPin className="w-4 h-4 text-gray-500" />
                <span>{job.location}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <Clock className="w-4 h-4 text-gray-500" />
                <span>{job.hours}</span>
              </div>
              <p className="text-gray-900 font-medium">{job.salary}</p>
            </div>
          )}
        </div>

        {/* Button section pushed to the bottom */}
        <div className="mt-auto pt-6">
          <button
            onClick={() => {
              if (isFutureJob) {
                window.location.href = 'http://jobseeker.asendia.ai/apply';
              } else {
                onApply(job.slug, job);
              }
            }}
            className={isFutureJob ? futureButtonClass : defaultButtonClass}
          >
            {isFutureJob ? (
              <span>Apply Now 🚀</span>
            ) : (
              <>
                <Zap className="w-5 h-5" />
                <span>Apply Now</span>
                <ArrowRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" />
              </>
            )}
          </button>

          {/* Render the Refer & Earn button only for non-Future job cards */}
          {!isFutureJob && (
            <a
              href="http://jobseeker.asendia.ai/referrals"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 w-full flex items-center justify-center gap-2 py-3 px-6 rounded-xl font-semibold border-2 border-gray-200 text-gray-800 hover:border-gray-300 hover:bg-gray-100 transition-all"
            >
              <Gift className="w-5 h-5" />
              Refer & Earn
            </a>
          )}
        </div>
      </div>

      {/* Glow Effect */}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="absolute -inset-2 bg-gray-200/20 blur-lg" />
      </div>
    </div>
  );
};

// New Filter Bar Component
const FilterBar = ({ 
  searchTerm, 
  setSearchTerm, 
  sortOption, 
  setSortOption,
  jobTypes,
  selectedJobTypes,
  setSelectedJobTypes
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <motion.div 
      variants={itemVariants}
      className="bg-white rounded-2xl shadow-sm border border-gray-100 p-4 mb-8"
    >
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
        {/* Search Bar */}
        <div className="relative flex-grow md:max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search job titles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all"
          />
        </div>

        {/* Sort and Filter Controls */}
        <div className="flex items-center space-x-3">
          {/* Sort Dropdown */}
          <div className="relative">
            <label className="text-sm text-gray-500 mr-2">Sort by:</label>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="appearance-none bg-gray-50 border border-gray-200 rounded-lg py-2 pl-4 pr-10 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"
            >
              <option value="newest">Most Recent</option>
              <option value="alphabetical">Job Title (A-Z)</option>
              <option value="alphabetical-reverse">Job Title (Z-A)</option>
            </select>
            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-4 h-4 pointer-events-none" />
          </div>

          {/* Advanced Filters Button */}
          <button 
            onClick={() => setFiltersOpen(!filtersOpen)}
            className="flex items-center gap-1.5 py-2 px-4 rounded-lg bg-gray-50 hover:bg-gray-100 border border-gray-200 transition-colors"
          >
            <SlidersHorizontal className="w-4 h-4" />
            <span className="text-sm font-medium">Filters</span>
          </button>
        </div>
      </div>

      {/* Advanced Filters Panel */}
      {filtersOpen && (
        <motion.div 
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-4 pt-4 border-t border-gray-100"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <h3 className="text-sm font-medium mb-2">Job Type</h3>
              <div className="space-y-2">
                {jobTypes.map((type) => (
                  <label key={type} className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedJobTypes.includes(type)}
                      onChange={() => {
                        if (selectedJobTypes.includes(type)) {
                          setSelectedJobTypes(selectedJobTypes.filter(t => t !== type));
                        } else {
                          setSelectedJobTypes([...selectedJobTypes, type]);
                        }
                      }}
                      className="rounded text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm">{type}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

// No Results Component
const NoResultsFound = () => {
  return (
    <motion.div 
      variants={itemVariants} 
      className="w-full py-16 flex flex-col items-center justify-center text-center"
    >
      <div className="bg-gray-100 p-6 rounded-full mb-4">
        <Search className="w-8 h-8 text-gray-400" />
      </div>
      <h3 className="text-xl font-semibold text-gray-800 mb-2">No matching jobs found</h3>
      <p className="text-gray-500 max-w-md">
        Try adjusting your search or filter criteria to find more opportunities.
      </p>
    </motion.div>
  );
};

const JobsPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const [filteredJobs, setFilteredJobs] = useState(JobsData);
  
  // Extract unique job types from data
  const jobTypes = [...new Set(JobsData.map(job => job.type))];
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);

  // When a job card's button is clicked, navigate to the job detail page passing the job object
  // (except for the Future jobs card which redirects directly)
  const handleApply = (slug, job) => {
    navigate(`/jobs/${slug}`, { state: { job } });
  };

  // Update filtered jobs whenever search term, sort option, or selected job types change
  useEffect(() => {
    // Separate the "Future Jobs" job from the rest
    const futureJobsJob = JobsData.find(job => job.slug === 'future-jobs');
    let otherJobs = JobsData.filter(job => job.slug !== 'future-jobs');

    // Filter by search term (only for other jobs)
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      otherJobs = otherJobs.filter(job => 
        job.title.toLowerCase().includes(searchTermLower) ||
        job.description?.toLowerCase().includes(searchTermLower)
      );
    }

    // Filter by job types (only for other jobs)
    if (selectedJobTypes.length > 0) {
      otherJobs = otherJobs.filter(job => selectedJobTypes.includes(job.type));
    }

    // Sort jobs based on selected option (only for other jobs)
    switch (sortOption) {
      case 'newest':
        otherJobs = otherJobs.sort((a, b) => {
          if (!a.postedDate) return 1;
          if (!b.postedDate) return -1;
          return new Date(b.postedDate) - new Date(a.postedDate);
        });
        break;
      case 'alphabetical':
        otherJobs = otherJobs.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'alphabetical-reverse':
        otherJobs = otherJobs.sort((a, b) => b.title.localeCompare(a.title));
        break;
      default:
        break;
    }

    // Combine "Future Jobs" (if it exists) at the top with the filtered/sorted other jobs
    const finalJobsList = futureJobsJob ? [futureJobsJob, ...otherJobs] : otherJobs;
    setFilteredJobs(finalJobsList);
  }, [searchTerm, sortOption, selectedJobTypes]);

  return (
    <>
      <Helmet>
        <title>Join Asendia AI Jobs</title>
        <meta
          name="description"
          content="Join our global team of exceptional talent. Work with brilliant minds in tech, grow your career, and make an impact in a culture built on collaboration and innovation. Explore our open roles at Asendia AI!"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-50 py-16 px-6">
        <motion.div initial="hidden" animate="visible" className="max-w-5xl mx-auto">
          <div className="flex flex-col items-center text-center mb-12 -mt-4">
            <motion.h2 variants={itemVariants} className="text-3xl sm:text-4xl font-bold text-slate-900 mt-2">
              Join Asendia AI
            </motion.h2>
            <motion.p variants={itemVariants} className="text-gray-600 text-base sm:text-lg mt-4 max-w-lg">
              Join our global team of exceptional talent. Work with brilliant minds in Tech, grow, and make an impact in a culture built on collaboration and innovation. Explore our open roles!
            </motion.p>
          </div>

          {/* Filter Bar */}
          <FilterBar 
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sortOption={sortOption}
            setSortOption={setSortOption}
            jobTypes={jobTypes}
            selectedJobTypes={selectedJobTypes}
            setSelectedJobTypes={setSelectedJobTypes}
          />

          {/* Job Listings */}
          {filteredJobs.length > 0 ? (
            <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
              {filteredJobs.map((job, index) => (
                <motion.div key={index} variants={itemVariants} className="h-full">
                  <JobCard job={job} onApply={handleApply} />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <NoResultsFound />
          )}
        </motion.div>
      </div>
    </>
  );
};

export default JobsPage;