import React, { useState } from 'react';
import { motion } from "framer-motion";
import { AiFillRocket, AiOutlineCoffee, AiOutlineAudit, AiOutlineFileText } from "react-icons/ai";
import NewsletterSection from "../../../Components/NewsLetter/NewsLetter";

// ======================= Framer Motion Variants =======================
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
};

// ======================= Inline SVG Tick Component =======================
const SVGTick = () => (
    <svg
        className="w-6 h-6 flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#FFFFFF"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M5 13l4 4L19 7"
        />
    </svg>
);


// ======================= Feature Icon Component =======================
const FeatureIcon = ({ children }) => (
    <div className="w-12 h-12 text-indigo-600 flex items-center justify-center mb-4">
        {children}
    </div>
);


const FAQSection = () => {
    return (
        <div className="max-w-[85rem] lg:pt-16 pb-16 sm:pb-28 px-4 sm:px-6 lg:px-8 mx-auto">
            {/* Title */}
            <div className="max-w-2xl mx-auto text-center mb-10">
                <h2 className="text-2xl font-bold lg:text-3xl lg:leading-tight">Pricing FAQ</h2>
                <p className="mt-1 text-gray-500">Answers to the most frequently asked questions.</p>
            </div>
            {/* End Title */}

            <div className="max-w-4xl mx-auto">
                {/* List */}
                <div className="space-y-10">
                    {/* Pricing FAQs Section */}
                    <div>
                        <div className="space-y-8">
                            <AccordionItem
                                id="how-does-pricing-work"
                                question="How does the pricing work?"
                            >
                                <p className="mt-2 text-gray-700">
                                    Our pricing is simple: you pay $0.2 per minute of usage. You only pay for the time you use, ensuring flexibility and transparency.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="are-there-any-hidden-costs"
                                question="Are there any hidden costs?"
                            >
                                <p className="mt-2 text-gray-700">
                                    No, there are no hidden costs. You only pay $0.2 per minute, and there are no additional or unexpected fees.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="is-there-a-subscription"
                                question="Is there a subscription required?"
                            >
                                <p className="mt-2 text-gray-700">
                                    No subscription is required. You pay as you go, meaning you only pay for the minutes you use without any long-term commitment.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="how-can-i-track-usage"
                                question="How can I track my usage?"
                            >
                                <p className="mt-2 text-gray-700">
                                    You can track your usage in your account dashboard, which shows the total minutes used and remaining balance if applicable.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="can-i-purchase-more-time"
                                question="Can I purchase more time if needed?"
                            >
                                <p className="mt-2 text-gray-700">
                                    Yes, you can add more minutes anytime as needed. Simply purchase additional time through your account.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                id="do-i-need-to-use-time-immediately"
                                question="Do I need to use my minutes immediately?"
                            >
                                <p className="mt-2 text-gray-700">
                                    No, you can use your purchased minutes at your convenience. There’s no expiration date, giving you full flexibility.
                                </p>
                            </AccordionItem>
                        </div>
                    </div>
                    {/* End Pricing FAQs Section */}
                </div>
                {/* End List */}

                {/* End Card */}
            </div>
        </div>
    );
};

const AccordionItem = ({ id, question, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="hs-accordion" id={id}>
            <button
                className="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-gray-800 disabled:opacity-50 disabled:pointer-events-none focus:outline-none"
                aria-expanded={isOpen}
                aria-controls={`${id}-heading`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {question}
                <svg
                    className={`hs-accordion-active:-rotate-180 transition-transform duration-300 size-4 ${isOpen ? '-rotate-180' : 'rotate-0'
                        }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m6 9 6 6 6-6"></path>
                </svg>
            </button>
            <div
                id={`${id}-heading`}
                className={`hs-accordion-content overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'
                    }`}
                role="region"
                aria-labelledby={id}
            >
                <div className="pt-3 pb-7 relative border-b border-gray-200">
                    {children}
                </div>
            </div>
        </div>
    );
};
// ======================= Landing Page =======================
const LandingPage = () => {
    return (
        <div className="min-h-screen w-full bg-white text-gray-800 font-sans">
            {/* ========== PAGE WRAPPER ========== */}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">

                {/* ========== HEADER WITH MOTION ========== */}
                <motion.div
                    className="max-w-2xl mx-auto text-center mb-16"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <section className="flex flex-col items-center text-center space-y-6">
                        <motion.h1
                            className="text-4xl sm:text-4xl md:text-5xl font-bold text-gray-900"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                        >
                            Empower Your Growth with{" "}
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 to-indigo-700 text-transparent">
                                Transparent Pricing
                            </span>
                        </motion.h1>
                        <motion.p
                            className="text-gray-600 max-w-3xl mx-auto text-lg sm:text-xl leading-relaxed"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                        >
                            Scalable pricing plans tailored to grow with your business needs.
                        </motion.p>

                    </section>
                </motion.div>

                {/* ========== PRICING SECTION ========== */}
                <section
                    id="pricing"
                    className="relative isolate overflow-hidden bg-white border rounded-3xl px-8 py-12 md:px-16 md:py-14 shadow-lg"
                >
                    {/* Decorative SVG Background */}

                    <div className="text-center mb-14 relative z-10">
                        <p className="text-5xl md:text-7xl font-bold text-gray-800">
                            $0.2{" "}
                            <span className="text-xl md:text-2xl font-medium text-gray-500">
                                / per minute
                            </span>
                        </p>
                        <p className="mt-4 text-lg text-gray-600">
                            Flexible pricing designed to scale with your needs.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
                        {/* Card 1 */}
                        <motion.div
                            className="relative p-8 bg-gray-50 rounded-2xl shadow-md hover:shadow-xl transition-shadow transform hover:-translate-y-2"
                            variants={itemVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <div
                                className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-indigo-600 rounded-full p-4 shadow-lg flex items-center justify-center"
                            >
                                <SVGTick className="text-white w-6 h-6" />
                            </div>
                            <h3 className="mt-12 text-2xl font-semibold text-gray-800 text-center">
                                No Hidden Costs
                            </h3>
                            <p className="mt-4 text-gray-600 text-center">
                                Transparent pricing with no unexpected fees. Know exactly what you pay for.
                            </p>
                        </motion.div>

                        {/* Card 2 */}
                        <motion.div
                            className="relative p-8 bg-gray-50 rounded-2xl shadow-md hover:shadow-xl transition-shadow transform hover:-translate-y-2"
                            variants={itemVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <div
                                className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-indigo-600 rounded-full p-4 shadow-lg flex items-center justify-center"
                            >
                                <SVGTick className="text-white w-6 h-6" />
                            </div>
                            <h3 className="mt-12 text-2xl font-semibold text-gray-800 text-center">
                                No Subscription Required
                            </h3>
                            <p className="mt-4 text-gray-600 text-center">
                                Flexible usage without the constraints of monthly commitments.
                            </p>
                        </motion.div>

                        {/* Card 3 */}
                        <motion.div
                            className="relative p-8 bg-gray-50 rounded-2xl shadow-md hover:shadow-xl transition-shadow transform hover:-translate-y-2"
                            variants={itemVariants}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <div
                                className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-indigo-600 rounded-full p-4 shadow-lg flex items-center justify-center"
                            >
                                <SVGTick className="text-white w-6 h-6" />
                            </div>
                            <h3 className="mt-12 text-2xl font-semibold text-gray-800 text-center">
                                Pay as You Go
                            </h3>
                            <p className="mt-4 text-gray-600 text-center">
                                Only pay for what you use, ensuring maximum flexibility and cost-efficiency.
                            </p>
                        </motion.div>
                    </div>

                    <div className="text-center mt-12 relative z-10">
                        <a
                            href="https://jobseeker.asendia.ai/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block px-8 py-3 bg-indigo-600 text-white font-semibold text-lg rounded-full shadow-lg hover:bg-indigo-700 transition-colors duration-300"
                        >
                            Get Started
                        </a>
                    </div>
                </section>

                {/* ========== FEATURES SECTION ========== */}
                <motion.section
                    id="features"
                    className="mt-24"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <motion.h2
                        className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-12"
                        variants={itemVariants}
                    >
                        Our Features
                    </motion.h2>

                    {/* Grid container for the features */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Feature 1 */}
                        <motion.div
                            className="bg-white p-6 rounded-xl shadow hover:shadow-lg transition-shadow border border-gray-100"
                            variants={itemVariants}
                        >
                            <FeatureIcon>
                                <AiOutlineCoffee className="w-8 h-8 text-indigo-600" />
                            </FeatureIcon>
                            <h4 className="text-xl font-semibold mb-2 text-gray-800">
                                Coffee Calls with Sarah
                            </h4>
                            <p className="text-gray-600">
                                Connect with a Sarah for informal discussions and receive feedback to enhance your interview skills.
                            </p>
                        </motion.div>

                        {/* Feature 2 */}
                        <motion.div
                            className="bg-white p-6 rounded-xl shadow hover:shadow-lg transition-shadow border border-gray-100"
                            variants={itemVariants}
                        >
                            <FeatureIcon>
                                <AiOutlineAudit className="w-8 h-8 text-indigo-600" />
                            </FeatureIcon>
                            <h4 className="text-xl font-semibold mb-2 text-gray-800">
                                Mock Interviews
                            </h4>
                            <p className="text-gray-600">
                                Simulate real interview scenarios with AI-driven mock interviews to prepare you for any question.
                            </p>
                        </motion.div>

                        {/* Feature 3 */}
                        <motion.div
                            className="bg-white p-6 rounded-xl shadow hover:shadow-lg transition-shadow border border-gray-100"
                            variants={itemVariants}
                        >
                            <FeatureIcon>
                                <AiOutlineFileText className="w-8 h-8 text-indigo-600" />
                            </FeatureIcon>
                            <h4 className="text-xl font-semibold mb-2 text-gray-800">
                                Templates Practice
                            </h4>
                            <p className="text-gray-600">
                                Practice with templates from top companies like Google, Meta, and Amazon to master their interview formats.
                            </p>
                        </motion.div>
                    </div>
                </motion.section>


                <FAQSection />
                <div class="my-20 w-[80%] mx-auto h-[1px]"></div>


                {/* ========== NEWSLETTER SECTION ========== */}
                <NewsletterSection />
            </div>
            {/* ========== END PAGE WRAPPER ========== */}
        </div>
    );
};

export default LandingPage;
