// src/components/TalkToFounder.jsx

import React, { useState, useRef, useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { FiArrowRight } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

// Utility function to validate business emails
const isBusinessEmail = (email) => {
  const freeEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "zoho.com",
    "yandex.com",
    "protonmail.com",
  ];
  const domain = email.split("@")[1]?.toLowerCase();
  if (!domain) return false;
  return !freeEmailDomains.includes(domain);
};

// Loader Component for general use
const Loader = () => (
  <div className="flex items-center justify-center">
    <div className="w-12 h-12 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

// Framer Motion variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 20,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1,
    opacity: 0.9,
    boxShadow: '0px 10px 20px rgba(132, 132, 132, 0.11)',
    transition: {
      yoyo: Infinity,
    },
  },
  tap: {
    scale: 0.95,
  },
};

const TalkToFounder = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
  });
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(true);

  const calendlyRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // State to handle submission status
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Business email is required.";
    } else if (!isBusinessEmail(formData.email)) {
      formErrors.email = "Please enter a valid business email.";
    }
    if (!formData.company.trim()) {
      formErrors.company = "Company name is required.";
    }
    if (!phone || phone.trim() === "" || !phone.match(/^[+0-9]+$/)) {
      formErrors.phone = "A valid phone number is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setCurrentStep(2);
    } else {
      toast.error("Please fix the errors in the form.", {
        position: "bottom-left",
      });
    }
  };

  const handleCalendlyLoad = () => {
    setIsCalendlyLoading(false);
  };

  /**
   * Calendly's embed "event_scheduled" post message
   * typically looks like:
   * {
   *   event: "calendly.event_scheduled",
   *   payload: {
   *     event_uri: "https://calendly.com/...",
   *     invitee_uri: "https://calendly.com/...",
   *   }
   * }
   * By default, NO name/email in the payload. We’ll rely on form data for that.
   */
  const handleCalendlyEvent = (e) => {
    // Ensure we're listening to Calendly's post messages only
    if (e.data.event && e.data.event.indexOf('calendly') !== -1) {
      // When an event is successfully scheduled
      if (e.data.event === 'calendly.event_scheduled') {
        const calendlyPayload = e.data.payload;


        console.log("calendlyPayload",calendlyPayload)

        // Calendly gives us an object like:
        // {
        //   event: { uri, name, start_time, end_time, ... },
        //   invitee: { name, email, ... }
        // }
        const calendlyEvent = calendlyPayload.event;
        const invitee = calendlyPayload.invitee;

        // Prepare data to send to your API
        const dataToSend = {
          name: formData.name,
          email: formData.email,
          company: formData.company,
          phone: phone,
          calendly: {
            eventUri: calendlyEvent.uri,
            eventName: calendlyEvent.name,
            startTime: calendlyEvent.start_time,
            endTime: calendlyEvent.end_time,
            invitee: {
              name: invitee.name,
              email: invitee.email,
            },
          },
        };

        // Call your API
        setIsSubmitting(true);
        fetch('https://recruiter.asendia.ai/api/book-demo', { // Replace if needed
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            toast.success("Demo booked successfully!", {
              position: "bottom-left",
            });
            // Optionally navigate or reset form
            // navigate('/thank-you');
          })
          .catch(error => {
            console.error("Error:", error);
            toast.error("There was an error booking your demo. Please try again.", {
              position: "bottom-left",
            });
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  useEffect(() => {
    // Listen for Calendly events
    window.addEventListener("message", handleCalendlyEvent);
    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, [formData, phone]);

  useEffect(() => {
    if (calendlyRef.current) {
      const iframe = calendlyRef.current.querySelector("iframe");
      if (iframe) {
        iframe.onload = handleCalendlyLoad;
      }
    }
  }, [currentStep]);

  return (
    <>
      <ToastContainer />

      {/* Outer wrapper that handles the background and spacing */}
      <motion.div
        className={`${
          currentStep === 2
            ? "bg-gray-50 py-16 min-h-screen flex items-center justify-center p-4"
            : "bg-gray-50 py-[8rem] flex items-center justify-center p-4"
        }`}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* 
          Parent container that now auto-resizes based on content 
          (removed 'overflow-hidden' and forced height).
        */}
        <motion.div
          className={`border bg-white shadow-lg rounded-3xl ${
            currentStep === 2 
              ? "max-w-4xl w-full h-auto" // auto height for step 2
              : "max-w-lg w-full"
          }`}
          variants={containerVariants}
        >
          {/* ------------------ STEP 1: FORM ------------------ */}
          {currentStep === 1 && (
            <motion.div
              className="p-8 max-w-2xl mx-auto bg-white shadow-lg rounded-xl"
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.h2
                className="text-3xl font-bold text-gray-800 mb-6 text-center"
                variants={itemVariants}
              >
                Let&apos;s Connect
              </motion.h2>

              <motion.form onSubmit={handleSubmit} className="space-y-6">
                {/* Name Field */}
                <motion.div variants={itemVariants}>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your Name"
                    className={`w-full mt-2 px-4 py-3 border rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 ${
                      errors.name ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.name && <p className="text-sm text-red-500 mt-1">{errors.name}</p>}
                </motion.div>

                {/* Email Field */}
                <motion.div variants={itemVariants}>
                  <label className="block text-sm font-medium text-gray-700">Business Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="you@business.com"
                    className={`w-full mt-2 px-4 py-3 border rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.email && <p className="text-sm text-red-500 mt-1">{errors.email}</p>}
                </motion.div>

                {/* Company Field */}
                <motion.div variants={itemVariants}>
                  <label className="block text-sm font-medium text-gray-700">Company Name</label>
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    placeholder="Your Company Name"
                    className={`w-full mt-2 px-4 py-3 border rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 ${
                      errors.company ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.company && <p className="text-sm text-red-500 mt-1">{errors.company}</p>}
                </motion.div>

                {/* Phone Field */}
                <motion.div variants={itemVariants}>
                  <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <PhoneInput
                    value={phone}
                    onChange={setPhone}
                    placeholder="Enter phone number"
                    defaultCountry="US"
                    international
                    className={`w-full mt-2 px-4 py-3 border rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.phone && <p className="text-sm text-red-500 mt-1">{errors.phone}</p>}
                </motion.div>

                {/* Submit Button */}
                <motion.div variants={itemVariants}>
                  <motion.button
                    type="submit"
                    className="flex justify-center items-center w-full py-3 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-semibold rounded-full shadow-sm hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 transition-all"
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <span>Next</span>
                    <FiArrowRight className="ml-2 mt-1 text-lg" />
                  </motion.button>
                </motion.div>
              </motion.form>
            </motion.div>
          )}

          {/* ------------------ STEP 2: CALENDLY ------------------ */}
          {currentStep === 2 && (
            <motion.div
              className="p-8 flex flex-col justify-center items-center"
              variants={itemVariants}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.h2
                className="text-2xl font-bold text-gray-800 mb-4 text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                Schedule a Call
              </motion.h2>

              <motion.p
                className="text-center text-gray-600 mb-6"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                Thank you for providing your details! Use the calendar below to book a call.
              </motion.p>

              {/* Loader for Calendly iFrame */}
              {isCalendlyLoading && (
                <motion.div
                  className="h-20 flex justify-center items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Loader />
                  <span className="ml-4 text-indigo-600 font-semibold">
                    Loading Calendly...
                  </span>
                </motion.div>
              )}

              {/* Calendly Widget */}
              <motion.div
                className={`bg-gray-50 rounded-md shadow-sm w-full ${
                  isCalendlyLoading ? "hidden" : "block"
                }`}
                ref={calendlyRef}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <InlineWidget
                  url="https://calendly.com/badis_zti-asendia/30min"
                  styles={{ height: "920px", width: "100%" }}
                  pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideGdprBanner: true,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                  prefill={{
                    name: formData.name,
                    email: formData.email,
                  }}
                />
              </motion.div>

              {/* Back Button */}
              <motion.button
                onClick={() => setCurrentStep(1)}
                className="mt-6 w-full max-w-sm py-3 text-gray-700 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none flex items-center justify-center"
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                <FaArrowLeft className="inline-block mr-2" /> Back
              </motion.button>

              {/* Submitting Indicator for your API booking */}
              {isSubmitting && (
                <div className="mt-6 flex flex-col items-center text-center space-y-2">
                  <Loader />
                  <span className="text-gray-700 font-medium">
                    Submitting your booking...
                  </span>
                </div>
              )}
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default TalkToFounder;
